import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './FreeGuide.css';
import FrequentlyAsked from "../faq/FrequentlyAsked";
import {Slide} from "react-awesome-reveal";

const TESTIMONIALS = [
    {
        id: 6,
        name: 'Harry',
        role: 'CS Student',
        avatar: require('../../assets/stockImages/avatar1.png'),
        review: `Absolutely mind-blowing! He helps me to understand concepts well by explaining them in different ways that he thinks will help my comprehension of the topic, and often gives an example to follow when doing work.`,
    },
    {
        id: 0,
        name: 'Omar Taky',
        role: 'AI Enthusiast',
        avatar: require('../../assets/stockImages/avatar2.png'),
        review: `I would like to commend the exceptional teaching prowess of instructors Faisal and Anouar at Learnfinity. Their expertise and passion for their subject matter is inspiring.`,
    },
];

const FreeGuide = () => {
    const [email, setEmail] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [userName, setUserName] = useState('User');

    const handleSubmit = (e) => {
        e.preventDefault();

        const templateParams = {
            email: email,
            to_name: userName,
            pdf_link: 'https://drive.google.com/file/d/1Jf-obNtzsv4lBwxXk7UaPEq3PZbvrLgW/view?usp=drive_link'
        };

        emailjs.send(
            'service_2aksw1r',
            'template_abtnoa5',
            templateParams,
            'x9DBTjmJ0xIDngIWX'
        ).then((response) => {
            console.log('SUCCESS!', response.status, response.text);
            alert('The guide has been sent to your email!');
            setShowPopup(false);
        }).catch((err) => {
            console.error('FAILED...', err);
            alert('Failed to send the guide. Please try again.');
        });
    };

    const handlePopupOpen = () => {
        setShowPopup(true);
    };

    const handlePopupClose = () => {
        setShowPopup(false);
    };

    return (
        <div className="container">
            <div className="main-content">
                <section className="intro">
                    <h2 className="fade-in">Get Your Free Tech Career Guide</h2>
                    <p className="fade-in">Discover essential skills, learning paths, and insider tips to kickstart your tech career.</p>
                    <form onSubmit={handleSubmit} className="form fade-in">
                        <div className="form-group">
                            <label htmlFor="email">Enter your email to receive the guide:</label>
                            <input
                                type="email"
                                className="form-control"
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                        <div className="cta">
                            <button type="submit">Get Your Free Guide</button>
                        </div>
                    </form>
                </section>

                <section className="guide-overview">
                    <h2 className="slide-in">What's Inside the Guide?</h2>
                    <ul>
                        <li>Essential Skills for Tech Careers</li>
                        <li>Learning Paths and Resources</li>
                        <li>Tips from Industry Experts</li>
                        <li>Career Growth Strategies</li>
                    </ul>
                    <div className="sample-pages">
                        <img src={require("../../assets/stockImages/man-working.jpg")} alt="man-working" />
                        <img src={require("../../assets/stockImages/free-guide.png")} alt="free-guide" />
                    </div>
                </section>

                <section className="benefits">
                    <h2 className="slide-in">Why Choose a Tech Career?</h2>
                    <ul>
                        <li>High Demand: Tech jobs are growing 2x faster than other industries.</li>
                        <li>Lucrative Salaries: Average tech salary is $85,000+ annually.</li>
                        <li>Flexible Work: Many tech roles offer remote work options.</li>
                    </ul>
                    <div className="video-container">
                        <video controls autoPlay muted loop width="900">
                            <source src={require("../../assets/videoAssets/programming.mp4")} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </section>

                <section className="testimonials">
                    <h1 className="slide-in">What Our Students Had To Say</h1>
                    <div className="testimonial-container">
                        {TESTIMONIALS.map((testimonial, index) => (
                            <div key={index} className="testimonial-card">
                                <div className="text">
                                    {testimonial.review}
                                    <i className="fas fa-quote-right quote"></i>
                                </div>
                                <div className="footer">
                                    <div className="image" style={{ backgroundImage: `url(${testimonial.avatar})` }}></div>
                                    <h3 className="person">{testimonial.name}</h3>
                                    <p className="role">{testimonial.role}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </section>

                <Slide direction='left' duration={1500} triggerOnce>
                    <FrequentlyAsked />
                </Slide>

                <section className="feedback">
                    <h2 className="slide-in">We Value Your Feedback</h2>
                    <form className="feedback-form">
                        <div className="form-group">
                            <label htmlFor="feedback">Leave your comments or suggestions:</label>
                            <textarea id="feedback" className="form-control" rows="4"></textarea>
                        </div>
                        <div className="cta">
                            <button type="submit">Submit Feedback</button>
                        </div>
                    </form>
                </section>

                <section className="extra-cta">
                    <h3 className="fade-in">Ready to start your tech career?</h3>
                    <button onClick={handlePopupOpen} className="extra-cta-button">Download the Free Guide Now</button>
                </section>

                {showPopup && (
                    <div className="popup">
                        <div className="popup-content">
                            <h2>Enter Your Email</h2>
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label htmlFor="popup-email">Email:</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="popup-email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                </div>
                                <button type="submit">Submit</button>
                            </form>
                            <button className="close-button" onClick={handlePopupClose}>Close</button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default FreeGuide;
