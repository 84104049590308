import React from 'react';
import './Foundations.css';
import PaymentMethods from '../../PaymentMethods/PaymentMethod';
import { foundationsHeroList, foundationsModules, mentoringList } from '../../../data/FoundationsCourseData';
import Testimonials from '../../testimonials/Testimonials';
import FrequentlyAsked from '../../faq/FrequentlyAsked';
import { Slide } from 'react-awesome-reveal';
import ModuleBreakdown from '../../ModuleBreakdown/ModuleBreakdown';
import FoundationsHero from '../../HeroSection/FoundationsHero/FoundationsHero';
import { beginnerCourseFAQ } from '../../../data/FAQs';
import CertificationInfo from '../../CertificationInfo/CertificationInfo';
import { HashLink } from 'react-router-hash-link';
import CareerPathways from '../../CareerPathways/CareerPathways';
import CourseAudience from '../../CourseAudience/CourseAudience';
import { useLocation  } from 'react-router-dom';
import TestimonialVideo from '../../TestimonialVideo/TestimonialVideo';
import rocket from "../../../assets/smallAssets/rocket.png";
import TechCompanies from '../../techCompanies/TechCompanies';
import Mentoring from '../../Mentoring/Mentoring';
import Pricing from '../../pricing/Pricing';
import EndlessLearningTools from '../../EndlessLearningTools/EndlessLearningTools';
import { Helmet } from 'react-helmet';

const Foundations = () => {
  const location = useLocation();
  const course = location.pathname.replace('/', '');
  const title = 'No Experience Needed: Expert-led 1-1 Mentorship for Beginners';
  const subtitle = 'Embark on your journey to becoming a tech professional by mastering foundational and advanced concepts in software engineering with our comprehensive Tech Path program! Now available on the Learnfinity platform!';
  const techComapnyTitle = 'Our Software Development alumni are now working at:'
  const mentoringTitle = 'Become a Software Developer in weeks'
  const cleanPathname = location.pathname.replace(/\/+$/, '');
  const metaTitle = 'Learnfinity Foundations: Start Your Tech Career with No Experience';
  const metaDescription = 'Enroll in the Learnfinity Foundations course for beginners. Get expert-led mentorship and build a strong foundation in tech, even with no prior experience. Start your career journey now.';
  return (
   <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={metaDescription} />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:image" content="https://www.learnfinity.co.uk/images/lf.png" />
        <meta property="og:url" content="https://www.learnfinity.co.uk/foundations" />
        <meta property="og:type" content="website" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:title" content={metaTitle} />
        <meta property="twitter:description" content={metaDescription} />
        <meta property="twitter:image" content="https://www.learnfinity.co.uk/path/to/image.jpg" />
      </Helmet>
      <div className="beginner-course-container">
        <FoundationsHero course={course} title={title} foundationsHeroList={foundationsHeroList} video={true}/>
        <EndlessLearningTools />
        <div id="module-1">
          <ModuleBreakdown courseName={'foundationsModules'} course={foundationsModules} />
        </div>
        <Mentoring mentoringTitle={mentoringTitle} mentoringList={mentoringList} beginner={true}/>
        <div id="start-learning">
            <CourseAudience courseAudience={'foundationsModules'} />
        </div>
        <TestimonialVideo />
        <div className="course-buttons">          
          <HashLink to={`${cleanPathname}#pricing`} smooth>
            <button className="course-button">
              Start Learning Now
              <img width="30px" className="rocket" src={rocket} alt="rocket"/>
            </button>
          </HashLink>
        </div>
        <CertificationInfo />
        <PaymentMethods />
        {/* <div className="hero-buttons">          
          <HashLink to={`/pricing/your-journey-into-technology`} smooth>
            <button className="btn" id='alternate-button'>Start Learning Now <img width="30px" className="rocket" src={rocket} alt="rocket"/></button>
          </HashLink>
        </div> */}
        <div id="pricing">
          <Pricing courseName={'foundations'} />
        </div>
        <FrequentlyAsked color={'#0e346d'} faqs={beginnerCourseFAQ} id="foundations-faq"/>
    </div>
   </>
  );
};

export default Foundations;
