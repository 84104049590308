import React from 'react';
import './WhyTech.css';
import { Slide } from 'react-awesome-reveal';

function WhyTech() {
    const why_tech_data = [
        {
            heading: 'Competitive Salaries & Growth Potential',
            emoji: require('../../assets/smallAssets/whytech/money.png'),
            explanation: "Tech careers offer some of the most competitive salaries in the job market. With experience and continued learning, you can unlock even greater earning potential. The tech industry is constantly evolving, which means there's always room for growth and advancement in your chosen field.",
        },
        {
            heading: "High Demand & Job Security",
            emoji: require('../../assets/smallAssets/whytech/suitcase.png'),
            explanation: "The tech industry is booming! There's a constant demand for skilled IT professionals, leading to a strong job market and ample opportunities. Once you gain the necessary skills, you'll enjoy the security of knowing your expertise is highly valued."
        },
        {
            heading: "Flexible Work Options",
            emoji: require('../../assets/smallAssets/whytech/house.png'),
            explanation: "Most of the tech companies embrace flexible work arrangements, allowing for remote work or hybrid schedules. This work-life balance is a major perk for individuals seeking a flexible schedule or the ability to work from anywhere."
        },
        {
            heading: "Continuous Learning & Innovation",
            emoji: require('../../assets/smallAssets/whytech/books.png'),
            explanation: "The tech industry is fast-paced and constantly evolving. This means there's always something new to learn and exciting challenges to tackle. If you enjoy staying up-to-date with the latest trends and technologies, a career in IT offers a dynamic and stimulating environment."
        },
        {
            heading: "Diverse & Rewarding Career Paths",
            emoji: require('../../assets/smallAssets/whytech/hardworking.png'),
            explanation: "The IT field is vast, offering a variety of career paths to explore. Whether you're interested in web development, cybersecurity, data science, or network administration, there's a specialization that aligns with your interests and skills. This variety ensures you can find a fulfilling career that keeps you engaged and intellectually stimulated."
        },
        {
            heading: "An Amazing Range of Perks!",
            emoji: require('../../assets/smallAssets/whytech/gift.png'),
            explanation: "Not only do most companies have great work-life balance but many top tech companies allow for share options, private healthcare, gym memberships and much more. The world of tech is always looking to change norms and give more to it's staff"
        }
    ];

    return (
        <div className='why-tech-container'>
            <Slide direction='up' duration={1500} triggerOnce>
                <h1 >Why <span className='highlight'>Switch</span> to Tech?</h1>
            </Slide>
            <div className='why-tech-reasons-container'>
                {why_tech_data.map((item, index) =>
                    <Slide direction={index % 2 === 0 ? 'left' : 'right'} duration={1500} triggerOnce key={index}>
                        <div className='why-tech-individual-reasons-container'>
                            <div className='why-tech-individual-heading-container'>
                                {index % 2 === 0 ?
                                    <>
                                        <h3 className='highlight'>{item.heading}</h3>
                                        <h1 className='why-tech-emoji'><img src={item.emoji} alt={item.emoji} /></h1>
                                    </>
                                    :
                                    <>
                                        <h1 className='why-tech-emoji'><img src={item.emoji} alt={item.emoji} /></h1>
                                        <h3 className='highlight'>{item.heading}</h3>
                                    </>
                                }
                            </div>
                            <p className='why-tech-explanation'>{item.explanation}</p>
                        </div>
                    </Slide>
                )}
            </div>
        </div>
    );
}

export default WhyTech;
