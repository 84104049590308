import React from 'react';
import './Policies.css';
import { Helmet } from 'react-helmet';

const CookiePolicy = () => (
    <div className="policy-container">
        <Helmet>
            <title>Cookie Policy - Learnfinity</title>
            <meta name="description" content="Learnfinity's Cookie Policy outlines the use of cookies and tracking technologies on our website." />
            <meta property="og:title" content="Cookie Policy - Learnfinity" />
            <meta property="og:description" content="Learnfinity's Cookie Policy outlines the use of cookies and tracking technologies on our website." />
            <meta property="og:type" content="article" />
            <meta property="og:url" content="https://www.learnfinity.co.uk/cookie-policy" />
            <meta property="og:image" content="https://www.learnfinity.co.uk/images/lf.png" />
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:title" content="Cookie Policy - Learnfinity" />
            <meta property="twitter:description" content="Learnfinity's Cookie Policy outlines the use of cookies and tracking technologies on our website." />
            <meta property="twitter:image" content="https://www.learnfinity.co.uk/favicon.png" />
            <meta name="robots" content="index, follow" />
            <meta name="keywords" content="Learnfinity, Cookie Policy, Cookies, Tracking Technologies, Privacy, Website Policy" />
            <meta name="author" content="Learnfinity" />
            <meta name="theme-color" content="#000000" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta charSet="utf-8" />
        </Helmet>
        <h1>Cookie Policy</h1>
        <p>Last updated: 07/07/2023</p>
        
        <h2>Interpretation and Definitions</h2>
        <p>
            The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
        </p>
        <p>
            For the purposes of this Cookie Policy:
            <br />Company (referred to as either "the Company", "We", "Us" or "Our" in this Cookie Policy) refers to Learnfinity.
            <br />Service refers to the Website.
            <br />You means the individual accessing the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.
            <br />Website refers to Learnfinity, accessible from https://learnfinity.co.uk/
        </p>

        <h2>Use of Cookies</h2>
        <p>
            We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. The technologies We use may include:
        </p>
        <ul>
            <li><strong>Cookies or Browser Cookies.</strong> A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service. Unless you have adjusted Your browser setting so that it will refuse Cookies, our Service may use Cookies.</li>
            <li><strong>Flash Cookies.</strong> Certain features of our Service may use local stored objects (or Flash Cookies) to collect and store information about Your preferences or Your activity on our Service. Flash Cookies are not managed by the same browser settings as those used for Browser Cookies.</li>
            <li><strong>Web Beacons.</strong> Certain sections of our Service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity).</li>
        </ul>

        <h2>Types of Cookies We Use</h2>
        <ul>
            <li><strong>Necessary / Essential Cookies.</strong> These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.</li>
            <li><strong>Functionality Cookies.</strong> These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website.</li>
            <li><strong>Tracking and Performance Cookies.</strong> These Cookies are used to track information about traffic to the Website and how users use the Website. The information gathered via these Cookies may directly or indirectly identify you as an individual visitor. This is because the information collected is typically linked to a pseudonymous identifier associated with the device you use to access the Website. We may also use these Cookies to test new advertisements, pages, features or new functionality of the Website to see how our users react to them.</li>
            <li><strong>Targeting and Advertising Cookies.</strong> These Cookies track your browsing habits to enable Us to show advertising which is more likely to be of interest to You. These Cookies use information about your browsing history to group You with other users who have similar interests. Based on that information, and with Our permission, third party advertisers can place Cookies to enable them to show adverts which we think will be relevant to your interests while You are on third party websites.</li>
        </ul>

        <h2>Your Choices Regarding Cookies</h2>
        <p>
            If You prefer to avoid the use of Cookies on the Website, first You must disable the use of Cookies in your browser and then delete the Cookies saved in your browser associated with this website. You may use this option for preventing the use of Cookies at any time.
        </p>
        <p>
            If You do not accept Our Cookies, You may experience some inconvenience in your use of the Website and some features may not function properly.
        </p>

        <h2>More Information about Cookies</h2>
        <p>
            You can learn more about Cookies at the following third-party websites:
        </p>
        <ul>
            <li><a href="https://www.allaboutcookies.org/">All About Cookies</a></li>
            <li><a href="https://www.networkadvertising.org/">Network Advertising Initiative</a></li>
        </ul>
    </div>
);

export default CookiePolicy;
