import React from 'react';
import { FaMedal, FaCode, FaUserTie } from "react-icons/fa";
import { Helmet } from 'react-helmet';
import './WhyUs.css';

function WhyUs() {
    const why_us_data = [
        {
            heading: 'Innovative Learning Pathways',
            description: 'At Learnfinity, we go beyond traditional education by offering innovative learning pathways that equip students with the skills and knowledge needed for the future. Our curriculum is designed to stay ahead of industry trends, ensuring our students are always learning the most relevant and in-demand skills.',
            icon: <FaCode />,
        },
        {
            heading: 'Elite Industry Expertise',
            description: 'Our programs are led by industry experts who bring real-world experience and insider knowledge to the classroom. We select only the most experienced professionals who are not just educators, but mentors guiding our students towards achieving their career goals in the tech industry.',
            icon: <FaMedal />,
        },
        {
            heading: 'Personalized Mentorship',
            description: "We believe in the power of personalized mentorship. Our mentors work closely with each student, offering tailored guidance that aligns with their individual learning style and career aspirations. This approach ensures that every student receives the support they need to excel.",
            icon: <FaUserTie />,
        },
    ];

    return (
        <div>
            <Helmet>
                <title>Why Learnfinity? Innovating Tech Education for Career Success</title>
                <meta name="description" content="Discover why Learnfinity is the top choice for tech learners. Innovative learning pathways, elite industry expertise, and personalized mentorship to help you excel in your tech career." />
                <meta property="og:title" content="Why Learnfinity? Innovating Tech Education for Career Success" />
                <meta property="og:description" content="Discover why Learnfinity is the top choice for tech learners. Innovative learning pathways, elite industry expertise, and personalized mentorship to help you excel in your tech career." />
                <meta property="og:image" content="https://www.learnfinity.co.uk/images/lf.png" />
                <meta property="og:url" content="https://www.learnfinity.co.uk/why-us" />
                <meta property="twitter:title" content="Why Learnfinity? Innovating Tech Education for Career Success" />
                <meta property="twitter:description" content="Discover why Learnfinity is the top choice for tech learners. Innovative learning pathways, elite industry expertise, and personalized mentorship to help you excel in your tech career." />
                <meta property="twitter:image" content="https://www.learnfinity.co.uk/path-to-your-og-image.jpg" />
            </Helmet>
            <div className="why-us-cards-section">
                <h2>Why Choose <span className='highlight'>Us</span>?</h2>
                <div className="why-us-cards-container">
                    {why_us_data.map((card, index) => (
                        <div className="why-us-card" key={index}>
                            <div className="why-us-icon-container">
                            </div>
                            <div className="why-us-content">
                                <h2>{card.heading}</h2>
                                <p>{card.description}</p>
                            </div>
                            <div className="icon-faded">{card.icon}</div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default WhyUs;
