import React, { useState } from 'react';
import './Events.css';
import { eventsData } from '../../data/eventsData';
import logo from '../../assets/logos/learnfinity-logo.png';
import { Helmet } from 'react-helmet';

const EventModal = ({ event, onClose }) => {
    return (
        <div className="modal-overlay">
            <div className="modal-content event-modal">
                <button className="modal-close" onClick={onClose}>X</button>
                <h2 className='highlight'>{event.sneakPeek.title}</h2>
                <img src={event.sneakPeek.img} alt={event.img} />
                <p>{event.sneakPeek.description}</p>
            </div>
        </div>
    );
};

const Events = () => {
    const [selectedEvent, setSelectedEvent] = useState(null);

    const handleSneakPeekClick = (course) => {
        setSelectedEvent(course);
    };

    const handleCloseModal = () => {
        setSelectedEvent(null);
    };

    const handleEnroll = () => {
        window.location.href = 'https://form.jotform.com/Learnfinity/webinar';
    };

    return (
        <>
            <Helmet>
                <title>Upcoming Events and Webinars - Learnfinity</title>
                <meta name="description" content="Stay up-to-date with upcoming events and webinars by Learnfinity. Engage with industry experts and enhance your tech knowledge." />
                <meta property="og:title" content="Upcoming Events and Webinars - Learnfinity" />
                <meta property="og:description" content="Stay up-to-date with upcoming events and webinars by Learnfinity. Engage with industry experts and enhance your tech knowledge." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.learnfinity.co.uk/events" />
                <meta property="og:image" content="https://www.learnfinity.co.uk/images/lf.png" />
                <meta property="twitter:title" content="Upcoming Events and Webinars - Learnfinity" />
                <meta property="twitter:description" content="Stay up-to-date with upcoming events and webinars by Learnfinity. Engage with industry experts and enhance your tech knowledge." />
                <meta property="twitter:image" content="https://www.learnfinity.co.uk/favicon.png" />
            </Helmet>
            <div className="events-container">
                <h1 className='highlight'>Upcoming Webinars</h1>
                <div className="events-cards-container">
                    {eventsData.map((event, index) => (
                        <div className="event-card" key={index}>
                            <div className="event-image-container">
                                <img src={event.imgSrc} alt={event.title} className="event-image" />
                                {index >= 3 && (
                                    <div className="coming-soon-overlay">Coming Soon</div>
                                )}
                            </div>
                            <div className="event-info">
                                <div className="title-date-container">
                                    <h3 className="event-title highlight">{event.title}</h3>
                                    <p className="event-date">{index < 3 && event.date}</p>
                                </div>
                                <div className="event-host">
                                    <img src={logo} alt="Hosted by" className="event-host-logo" />
                                    <div>
                                        <p className='highlight'>Hosted by</p>
                                        <p className="event-host-name highlight">Learnfinity</p>
                                    </div>
                                    <div className="event-button-container">
                                        {!event.comingSoon ? (
                                            <button className="enroll-button" onClick={handleEnroll}>
                                                {event.buttonText}
                                            </button>
                                        ) : (
                                            <button className="enroll-button" onClick={() => handleSneakPeekClick(event)}>
                                                Sneak Peek
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {selectedEvent && <EventModal event={selectedEvent} onClose={handleCloseModal} />}
            </div>
        </>
    );
};

export default Events;
