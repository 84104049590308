import React, { useState } from 'react';
import { articlesList } from "../../../data/articles"; 
import articlesImage from "../../../assets/stockImages/articles.png";
import learnfinityLogo from "../../../assets/logos/learnfinity-logo.png"; 
import { Helmet } from 'react-helmet';

const Articles = () => {
    const [searchTerm, setSearchTerm] = useState('');

    const filteredArticles = articlesList.filter(article => 
        article.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        article.description.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleArticleClick = (article) => {
        window.location.href = `/articles/${article.id}`;
    };

    return (
        <>
            <Helmet>
                <title>Tech Articles and Resources - Learnfinity</title>
                <meta name="description" content="Explore the latest articles and resources on tech trends and insights. Learnfinity provides curated content to keep you informed and ahead in your tech career." />
                <meta property="og:title" content="Learnfinity Articles: Stay Updated with Tech Insights and Trends" />
                <meta property="og:description" content="Explore the latest articles and resources on tech trends and insights by Learnfinity." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.learnfinity.co.uk/articles" />
                <meta property="og:image" content="https://www.learnfinity.co.uk/images/lf.png" />
            </Helmet>
            <div className="container mx-auto p-4 md:p-6">
                <div className="flex justify-center mb-8">
                    <img src={articlesImage} alt="Articles" className="w-full max-w-xs h-auto" />
                </div>
                <h1 className="text-4xl md:text-5xl font-bold text-center mb-8 highlight leading-tight">Articles and Resources</h1>
                <div className="max-w-md mx-auto">
                    <p className="text-center text-base md:text-lg text-gray-600 mb-6 highlight">
                        Explore a curated collection of articles and resources by Learnfinity
                    </p>
                    <div className="mb-6 flex justify-center">
                        <input 
                            type="text" 
                            placeholder="Search through articles" 
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="w-full p-2 md:p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400"
                        />
                    </div>
                </div>
                <div className="flex flex-col gap-6">
                    {filteredArticles.map((article, index) => (
                        <div 
                            key={index} 
                            className="relative bg-white p-4 md:p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 flex flex-col md:flex-row cursor-pointer"
                            onClick={() => handleArticleClick(article)} 
                        >
                            <img 
                                src={article.coverImage} 
                                alt={article.title} 
                                className="w-full md:w-1/3 rounded-lg transition-shadow duration-300 hover:shadow-lg hover:shadow-cyan-500/50 mb-4 md:mb-0" 
                            />  
                            <div className="md:ml-6 flex-1">
                                <h3 className="text-lg md:text-xl font-semibold text-blue-600 mb-2 md:mb-4 relative highlight">{article.title}</h3>
                                <p className="text-gray-700 relative mb-4">{article.description}</p>
                                <img src={learnfinityLogo} alt="Learnfinity" className="w-12 h-auto" /> {/* Learnfinity logo */}
                            </div>
                        </div>
                    ))}
                    {filteredArticles.length === 0 && (
                        <div className="text-center text-gray-500">No articles found.</div>
                    )}
                </div>
            </div>
        </>
    );
};

export default Articles;
