export const fullStackModules = [
    {
        module: 1,
        title: "Foundations of Advanced Programming",
        keyBenefit: "Build a strong foundation in programming principles to tackle complex problems efficiently.",
        chapters: [
            { chapter: "1.1", title: "Advanced Python Concepts", time: "1:00:00", benefit: "Master advanced Python features to write cleaner, more efficient code." },
            { chapter: "1.2", title: "Object-Oriented Programming Deep Dive", time: "1:30:00", benefit: "Understand OOP to design modular, maintainable code." },
            { chapter: "1.3", title: "Functional Programming in Python", time: "1:15:00", benefit: "Leverage functional programming for more robust applications." },
            { chapter: "1.4", title: "Concurrency and Parallelism", time: "1:20:00", benefit: "Write code that can handle multiple tasks at once, efficiently." },
            { chapter: "1.5", title: "Best Practices in Coding Standards", time: "1:00:00", benefit: "Ensure your code adheres to industry standards for quality and maintainability." }
        ],
        total: "6:05:00"
    },
    {
        module: 2,
        title: "Full Stack Web Development",
        keyBenefit: "Gain expertise in building full-fledged web applications from scratch.",
        chapters: [
            { chapter: "2.1", title: "Advanced HTML & CSS Techniques", time: "1:30:00", benefit: "Create responsive, visually appealing web pages." },
            { chapter: "2.2", title: "JavaScript ES6+ Features", time: "1:15:00", benefit: "Master modern JavaScript to build dynamic web applications." },
            { chapter: "2.3", title: "Frontend Frameworks: React.js Deep Dive", time: "2:00:00", benefit: "Develop interactive user interfaces using React.js." },
            { chapter: "2.4", title: "Backend Development with Node.js and Express", time: "1:30:00", benefit: "Build scalable backend services with Node.js." },
            { chapter: "2.5", title: "Database Integration with MongoDB", time: "1:00:00", benefit: "Store and retrieve data efficiently using MongoDB." },
            { chapter: "2.6", title: "Building RESTful APIs", time: "1:15:00", benefit: "Create robust APIs to connect frontend and backend." },
            { chapter: "2.7", title: "WebSockets and Real-time Communication", time: "1:00:00", benefit: "Implement real-time features like chats and notifications." },
            { chapter: "2.8", title: "Authentication and Authorization", time: "1:00:00", benefit: "Secure your web applications with proper authentication methods." }
        ],
        total: "10:30:00"
    },
    { 
        module: 3, 
        title: 'Data Science and Machine Learning', 
        keyBenefit: "Master data science techniques and machine learning models to extract insights from data and build predictive applications.",
        chapters: [
            { chapter: "3.1", title: "Introduction to Data Science", time: "1:00:00", benefit: "Understand the fundamentals of data science and its real-world applications." },
            { chapter: "3.2", title: "Data Analysis with Pandas", time: "1:30:00", benefit: "Analyze and manipulate large datasets with ease using Pandas." },
            { chapter: "3.3", title: "Data Visualization with Matplotlib and Seaborn", time: "1:15:00", benefit: "Create compelling visualizations to communicate your data insights effectively." },
            { chapter: "3.4", title: "Machine Learning with Scikit-Learn", time: "1:30:00", benefit: "Build and evaluate machine learning models with Scikit-Learn." },
            { chapter: "3.5", title: "Deep Learning with TensorFlow and Keras", time: "2:00:00", benefit: "Dive into neural networks and deep learning with TensorFlow." },
            { chapter: "3.6", title: "Natural Language Processing", time: "1:45:00", benefit: "Process and analyze text data for insights and automation." },
            { chapter: "3.7", title: "Real-world Data Science Projects", time: "2:00:00", benefit: "Apply your skills to solve real-world data science challenges." }
        ],
        total: "11:00:00"
    },
    { 
        module: 4, 
        title: 'DevOps and Cloud Computing', 
        keyBenefit: "Learn to automate, monitor, and scale infrastructure on cloud platforms with a strong DevOps foundation.",
        chapters: [
            { chapter: "4.1", title: "Introduction to DevOps", time: "1:00:00", benefit: "Grasp the core principles of DevOps and its importance in modern IT." },
            { chapter: "4.2", title: "Containerization with Docker", time: "1:30:00", benefit: "Package and deploy applications consistently with Docker containers." },
            { chapter: "4.3", title: "Continuous Integration and Continuous Deployment", time: "1:15:00", benefit: "Automate the testing and deployment process to improve software quality." },
            { chapter: "4.4", title: "Infrastructure as Code with Terraform", time: "1:15:00", benefit: "Manage and provision infrastructure efficiently using Terraform." },
            { chapter: "4.5", title: "Cloud Computing with AWS", time: "2:00:00", benefit: "Leverage AWS services to deploy and manage scalable applications." },
            { chapter: "4.6", title: "Monitoring and Logging with Prometheus and Grafana", time: "1:30:00", benefit: "Ensure application performance and reliability with effective monitoring tools." },
            { chapter: "4.7", title: "Security Best Practices in DevOps", time: "1:00:00", benefit: "Implement security best practices to safeguard your infrastructure." }
        ],
        total: "9:30:00"
    },
    { 
        module: 5, 
        title: 'Advanced Cybersecurity', 
        keyBenefit: "Protect systems and networks by mastering advanced cybersecurity techniques and protocols.",
        chapters: [
            { chapter: "5.1", title: "Introduction to Cybersecurity", time: "1:00:00", benefit: "Understand the fundamentals of securing systems and data." },
            { chapter: "5.2", title: "Network Security Fundamentals", time: "1:30:00", benefit: "Protect and secure network infrastructures against threats." },
            { chapter: "5.3", title: "Ethical Hacking and Penetration Testing", time: "1:45:00", benefit: "Test and evaluate the security of systems through ethical hacking techniques." },
            { chapter: "5.4", title: "Cryptography and Encryption Techniques", time: "1:30:00", benefit: "Implement cryptographic techniques to protect sensitive data." },
            { chapter: "5.5", title: "Incident Response and Management", time: "1:15:00", benefit: "Respond effectively to security incidents to minimize damage." },
            { chapter: "5.6", title: "Compliance and Regulatory Requirements", time: "1:00:00", benefit: "Ensure that your systems meet industry regulations and standards." },
            { chapter: "5.7", title: "Cybersecurity Project: Secure a Network", time: "2:00:00", benefit: "Apply your knowledge to secure a real-world network infrastructure." }
        ],
        total: "10:00:00"
    },
    { 
        module: 6, 
        title: 'Career Development and Job Readiness', 
        keyBenefit: "Prepare yourself to enter the job market with confidence and excel in your tech career.",
        chapters: [
            { chapter: "6.1", title: "Building a Strong Tech Resume", time: "0:45:00", benefit: "Craft a resume that stands out to potential employers." },
            { chapter: "6.2", title: "Effective Job Search Strategies", time: "0:45:00", benefit: "Learn how to effectively search for and land the right job opportunities." },
            { chapter: "6.3", title: "Interview Preparation: Technical and Behavioral", time: "1:00:00", benefit: "Ace both technical and behavioral interviews with confidence." },
            { chapter: "6.4", title: "Negotiating Job Offers", time: "0:45:00", benefit: "Secure the best possible terms and conditions in your job offers." },
            { chapter: "6.5", title: "Career Growth and Networking", time: "1:00:00", benefit: "Expand your professional network and grow your career in tech." },
            { chapter: "6.6", title: "Personal Branding and Online Presence", time: "0:45:00", benefit: "Build and maintain a strong personal brand online to attract opportunities." }
        ],
        total: "5:00:00"
    },
    {
        module: 7,
        title: 'Capstone Project and Presentation',
        keyBenefit: "Apply all your learning in a comprehensive project that showcases your skills to potential employers.",
        chapters: [
            { chapter: "7.1", title: "Project Planning and Proposal", time: "1:00:00", benefit: "Lay the groundwork for a successful project with detailed planning." },
            { chapter: "7.2", title: "Design and Prototyping", time: "1:30:00", benefit: "Create a prototype that outlines your project's key features." },
            { chapter: "7.3", title: "Implementation and Testing", time: "2:00:00", benefit: "Develop, test, and refine your project to meet professional standards." },
            { chapter: "7.4", title: "Final Presentation and Review", time: "1:00:00", benefit: "Present your project with confidence and receive valuable feedback." }
        ],
        total: "5:30:00"
    }
];

export const fullStackHeroList = [
    'Master cutting-edge technologies with hands-on projects',
    'Personalized mentorship from industry experts',
    'Build a portfolio that stands out in the competitive tech industry',
    'Flexible learning paths designed for busy professionals',
    'Achieve career advancement in high-demand tech fields'
];
