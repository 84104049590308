import React from 'react';
import './ContentOverview.css';

const ContentOverview = () => {
    return (
        <div className="content-overview-container">
            <div className="content-header">
                <h1 className="content-title highlight">An exercise-driven approach for learning.</h1>
                <p className="content-subtitle highlight">Learnfinity's approach is different than what you'll find in other online courses.</p>
            </div>
            <div className="content-body">
                <div className="content-side">
                    <img src={require('../../assets/materials/hw-exercises.png')} alt="Homework and Exercises" className="content-image-large" />
                    <div className="content-description highlight">
                        <h3>Homework & Exercises</h3>
                        <p>Our expert mentors guide you through every step, ensuring you gain a deep understanding of the material.</p>
                    </div>
                </div>
                <div className="content-side">
                    <img src={require('../../assets/materials/DB.png')} alt="Lecture Slides" className="content-image-large" />
                    <div className="content-description highlight">
                        <h3>Lecture Slides</h3>
                        <p>Review the materials at your own pace, ensuring you fully grasp each concept before moving forward.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContentOverview;
