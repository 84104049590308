import React from 'react';
import './Mentoring.css';
import ToolsIcons from '../ToolsIcons/ToolsIcons';
import tick from '../../assets/smallAssets/tick.png';
import TechCompanies from '../techCompanies/TechCompanies';
import pair from "../../assets/stockImages/pair-prog.png";
const Mentoring = ({ mentoringTitle, mentoringList, beginner }) => {
    return (
        <div className="tech-networking-container">
            <div className="tech-networking-content">
                <div className="mentor-help">
                    {beginner ? <h4>Turn Your Skills into a Career with Our Expert Guidance...</h4> : <h4> With the tools and technologies we teach you, you can...</h4> }
                    <h1 className='highlight'>{mentoringTitle}</h1>
                    {mentoringList ? (
                        <ul className="mentoring-list">
                            {mentoringList.map((list, index) => (
                                <li key={index} className="mentoring-item">
                                    <img className="tick-icon" src={tick} alt="check icon" /> {list}
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p>We teach all of the in-demand skills needed to land your <span className='highlight'>dream job</span> in tech</p>
                    )}
                </div>
            </div>
            <div className="tools-icon-section">
                {beginner ? <img src={pair} alt={pair} /> : <ToolsIcons lineColor={'#0e346d'} />}
            </div>
            {/* <TechCompanies /> */}
        </div>
    );
};

export default Mentoring;
