import React from 'react';
import './CertificationInfo.css';
import certificationImage from '../../assets/certification/Certification.png'; // Update the image path accordingly
import badgeImage from '../../assets/logos/learnfinity-logo.png'; // Update the image path accordingly
import { FaCertificate, FaMedal } from 'react-icons/fa';

const CertificationInfo = () => {
  return (
    <div className="certification-info-container">
      <h2 className='highlight'>Earn Your Certificate and Digital Badges</h2>
      <div className="certification-badge-content">
        <div className="icon-background">
          <FaCertificate className="background-icon icon-1" />
          <FaMedal className="background-icon icon-2" />
        </div>
        <div className="certification-content">
          <img src={certificationImage} alt="Certification" className="certification-image" />
          <div className="certification-text">
            <h3 className='highlight'>Certification</h3>
            <p>Upon successful completion of the Comprehensive Tech Path Program, you will receive a certified diploma that you can showcase on your resume and professional profiles.</p>
          </div>
        </div>
        <div className="badge-content">
          <img src={badgeImage} alt="Digital Badge" className="badge-image" />
          <div className="badge-text">
            <h3 className='highlight'>Digital Badges</h3>
            <p>Showcase your badges on your LinkedIn profile to attract potential employers and stand out in the job market.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CertificationInfo;
