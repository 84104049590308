import React from 'react';
import './Policies.css';
import { Helmet } from 'react-helmet';

const PrivacyPolicy = () => {
    return (
        <div className="policy-container">
            <Helmet>
                <title>Privacy Policy - Learnfinity</title>
                <meta name="description" content="Learnfinity's Privacy Policy explains how your personal information is collected, used, and disclosed by Learnfinity." />
                <meta property="og:title" content="Privacy Policy - Learnfinity" />
                <meta property="og:description" content="Learnfinity's Privacy Policy explains how your personal information is collected, used, and disclosed by Learnfinity." />
                <meta property="og:type" content="article" />
                <meta property="og:url" content="https://www.learnfinity.co.uk/privacy-policy" />
                <meta property="og:image" content="https://www.learnfinity.co.uk/path/to/your-image.jpg" />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:title" content="Privacy Policy - Learnfinity" />
                <meta property="twitter:description" content="Learnfinity's Privacy Policy explains how your personal information is collected, used, and disclosed by Learnfinity." />
                <meta property="twitter:image" content="https://www.learnfinity.co.uk/images/lf.png" />
            </Helmet>
            <h1>Privacy Policy</h1>
            <p>Effective Date: 07/07/2023</p>

            <p><strong>Learnfinity</strong> ("we," "our," or "us") is committed to protecting your privacy. This Privacy Policy explains how your personal information is collected, used, and disclosed by Learnfinity. This Privacy Policy applies to our website, <a href="https://www.learnfinity.co.uk">www.learnfinity.co.uk</a>, and its associated subdomains (collectively, our "Service").</p>

            <p>By accessing or using our Service, you signify that you have read, understood, and agree to our collection, storage, use, and disclosure of your personal information as described in this Privacy Policy and our Terms of Service.</p>

            <h2>1. Information We Collect</h2>
            <p>We collect information that you provide directly to us. The types of information we may collect include:</p>
            <ul>
                <li><strong>Personal Information:</strong> Name, email address, phone number, postal address, and other contact or identifying information you choose to provide.</li>
                <li><strong>Account Information:</strong> When you register for an account, we collect information about your account, such as your username and password.</li>
                <li><strong>Payment Information:</strong> If you make a purchase, we collect billing information, including your credit card number and billing address.</li>
                <li><strong>Usage Data:</strong> Information about how you use our Service, such as your interactions with the Service, IP address, browser type, and operating system.</li>
            </ul>

            <h2>2. How We Use Your Information</h2>
            <p>We use the information we collect in various ways, including to:</p>
            <ul>
                <li>Provide, operate, and maintain our Service.</li>
                <li>Improve, personalize, and expand our Service.</li>
                <li>Understand and analyze how you use our Service.</li>
                <li>Develop new products, services, features, and functionality.</li>
                <li>Process your transactions and manage your orders.</li>
                <li>Send you promotional communications, such as marketing emails, unless you have opted out.</li>
                <li>Respond to your comments, questions, and provide customer service.</li>
                <li>Send you technical notices, updates, and security alerts.</li>
            </ul>

            <h2>3. How We Share Your Information</h2>
            <p>We may share the information we collect in the following ways:</p>
            <ul>
                <li><strong>With Service Providers:</strong> We may share your information with third-party vendors and service providers who perform services on our behalf, such as payment processing, data analysis, email delivery, hosting services, and customer service.</li>
                <li><strong>For Legal Reasons:</strong> We may disclose your information if we believe it is necessary to comply with a legal obligation, protect and defend our rights or property, or prevent or investigate possible wrongdoing in connection with the Service.</li>
            </ul>

            <h2>4. Security of Your Information</h2>
            <p>We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable.</p>

            <h2>5. Your Data Protection Rights</h2>
            <p>Depending on your location, you may have the following rights regarding your personal information:</p>
            <ul>
                <li><strong>The right to access:</strong> You have the right to request copies of your personal data.</li>
                <li><strong>The right to rectification:</strong> You have the right to request that we correct any information you believe is inaccurate or complete information you believe is incomplete.</li>
                <li><strong>The right to erasure:</strong> You have the right to request that we erase your personal data, under certain conditions.</li>
                <li><strong>The right to restrict processing:</strong> You have the right to request that we restrict the processing of your personal data, under certain conditions.</li>
                <li><strong>The right to object to processing:</strong> You have the right to object to our processing of your personal data, under certain conditions.</li>
                <li><strong>The right to data portability:</strong> You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</li>
            </ul>

            <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us at our provided contact information.</p>

            <h2>Contact Us</h2>
            <p>If you have any questions about this Privacy Policy, please contact us at:</p>
            <p>Email: support@learnfinity.co.uk</p>
            <p>Address: 43 Priors Croft, London, UK</p>
        </div>
    );
}

export default PrivacyPolicy;
