import React from 'react';
import './CourseAudience.css';
import { foundationsAudience, fullStackAudience, advancedAudience} from '../../data/courseAudience';

const CourseAudience = ({ courseAudience }) => {
  const cardData = courseAudience === 'foundationsModules' ? foundationsAudience : courseAudience === 'fullStackModules' ? fullStackAudience : advancedAudience; 

  return (
    <div className="glass-cards-section">
      <h2>Who is the <span className='highlight'>course</span> for?</h2>
      <p>All you need is your laptop and motivation to push your design knowledge and skills to a new level. We’re starting from scratch!</p>
      <div className="glass-cards-container">
        {cardData.map((card, index) => (
          <div className="glass-card" key={index}>
            <div className="glass-icon-container">
              {/* <img src={card.imgSrc} alt={card.title} className="glass-icon" /> */}
            </div>
            <div className="glass-content">
              <h2>{card.title}</h2>
              <p>{card.description}</p>
            </div>
            <div className="icon-faded">{card.icon}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CourseAudience;
