export const SubjectTags = [
    {
        subject: "Python",
        // iconImage: require('../assets/icons/Starter.png'),
    },
    {
        subject: "Operating Systems",
        // iconImage: require('../assets/icons/Starter.png'),
    },
    {
        subject: "Git",
        // iconImage: require('../assets/icons/Starter.png'),
    },
    {
        subject: "Linux & CLI",
        // iconImage: require('../assets/icons/Starter.png'),
    },
    {
        subject: "Mini-projects",
        // iconImage: require('../assets/icons/Starter.png'),
    },
    {
        subject: "Mentor Checkpoints",
        // iconImage: require('../assets/icons/Starter.png'),
    },
    {
        subject: "Databases",
        // iconImage: require('../assets/icons/Starter.png'),
    },
    {
        subject: "Web Development",
        // iconImage: require('../assets/icons/Starter.png'),
    },
    {
        subject: "SQL",
        // iconImage: require('../assets/icons/Starter.png'),
    },
    {
        subject: "Cyber Security",
        // iconImage: require('../assets/icons/Starter.png'),
    },
    {
        subject: "Cloud Computing",
        // iconImage: require('../assets/icons/Starter.png'),
    },
    {
        subject: "Artificial Intelligence (AI)",
        // iconImage: require('../assets/icons/Starter.png'),
    },
    {
        subject: "Career Development",
        // iconImage: require('../assets/icons/Starter.png'),
    },
    {
        subject: "Project Management",
        // iconImage: require('../assets/icons/Starter.png'),
    },
    {
        subject: "Campus Day",
        // iconImage: require('../assets/icons/Starter.png'),
    },
]