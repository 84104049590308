import React, { useState } from 'react';
import './TechChallenges.css';
import devopsIcon from '../../assets/devops/aws-icon.png';
import cloudIcon from '../../assets/smallAssets/cloud-storage.png';
import securityIcon from '../../assets/smallAssets/cybersecurity.png';
import { HashLink } from 'react-router-hash-link';
import { useLocation  } from 'react-router-dom';
const challenges = [
    {
        title: "Automate a DevOps Pipeline",
        description: "Design and automate a CI/CD pipeline using Jenkins and Docker. Ensure that each commit triggers a build, test, and deployment process.",
        icon: devopsIcon,
        detailedDescription: "In this challenge, you'll create a Jenkins pipeline that automates the build, test, and deployment of a microservices application using Docker. You'll need to set up the necessary configuration files, integrate testing tools, and deploy the application to a Kubernetes cluster.",
        technologies: ["Jenkins", "Docker", "Kubernetes"],
    },
    {
        title: "Deploy a Scalable Cloud Application",
        description: "Deploy a web application on AWS with auto-scaling and load balancing to ensure high availability during traffic spikes.",
        icon: cloudIcon,
        detailedDescription: "For this challenge, you'll need to deploy a web application on AWS, configure an Elastic Load Balancer, and set up Auto Scaling Groups to automatically adjust the number of instances based on traffic. The challenge focuses on ensuring that the application remains available and performs well under different traffic conditions.",
        technologies: ["AWS", "Elastic Load Balancing", "Auto Scaling"],
    },
    {
        title: "Secure a Web Application",
        description: "Implement security best practices to protect a web application from common vulnerabilities like SQL injection, XSS, and CSRF.",
        icon: securityIcon,
        detailedDescription: "In this challenge, you will secure a web application by implementing measures to protect against SQL injection, Cross-Site Scripting (XSS), and Cross-Site Request Forgery (CSRF). You will also configure HTTPS and use security headers to further enhance the application's security.",
        technologies: ["Web Security", "HTTPS", "Security Headers"],
    },
];

const TechChallenges = () => {
    const [selectedChallenge, setSelectedChallenge] = useState(null);
    const location = useLocation();
    const cleanPathname = location.pathname.replace(/\/+$/, '');
    return (
        <section className="tech-challenges-section">
            <h2>Test Your Skills with <span className="highlight">Real-World Tech Challenges</span></h2>
            <p>Engage with hands-on challenges that reflect the real-world problems you'll learn to solve in our course.</p>
            <div className="challenges-grid">
                {challenges.map((challenge, index) => (
                    <div 
                        key={index} 
                        className="challenge-card" 
                        onClick={() => setSelectedChallenge(challenge)}
                    >
                        <img src={challenge.icon} alt={challenge.title} className="challenge-icon" />
                        <h3 className='highlight'>{challenge.title}</h3>
                        <p>{challenge.description}</p>
                    </div>
                ))}
            </div>

            {selectedChallenge && (
                <CourseModal onClose={() => setSelectedChallenge(null)}>
                    <h3 className='challenge'>{selectedChallenge.title}</h3>
                    <p className='challenge'>{selectedChallenge.detailedDescription}</p>
                    <p className='challenge'><strong>Technologies:</strong> {selectedChallenge.technologies.join(", ")}</p>
                    <HashLink to={`${cleanPathname}#pricing`} smooth>
                        <button className="btn cta-button" id='alternate-button'>Start Learning Now</button>
                    </HashLink>
                </CourseModal>
            )}
        </section>
    );
};

export default TechChallenges;

// CourseModal Component
const CourseModal = ({ children, onClose }) => {
    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <button className="modal-close" onClick={onClose}>X</button>
                {children}
            </div>
        </div>
    );
};

// CSS for TechChallenges and CourseModal
