export const eliteModulesQuizQuestions = [
    {
      question: "What is a common use case for Natural Language Processing (NLP)?",
      options: [
        "Image Recognition",
        "Text Sentiment Analysis",
        "Speech Synthesis",
        "Weather Prediction"
      ],
      correctAnswer: "Text Sentiment Analysis"
    },
    {
      question: "Which algorithm is typically used for classification tasks?",
      options: [
        "Linear Regression",
        "Decision Trees",
        "K-Means Clustering",
        "Principal Component Analysis"
      ],
      correctAnswer: "Decision Trees"
    },
    {
      question: "What is the purpose of the activation function in a neural network?",
      options: [
        "To initialize the weights",
        "To introduce non-linearity",
        "To normalize the input data",
        "To perform backpropagation"
      ],
      correctAnswer: "To introduce non-linearity"
    },
    {
      question: "Which of the following is a supervised learning algorithm?",
      options: [
        "K-Means Clustering",
        "Apriori Algorithm",
        "Support Vector Machine",
        "DBSCAN"
      ],
      correctAnswer: "Support Vector Machine"
    },
    {
      question: "What is the main goal of Principal Component Analysis (PCA)?",
      options: [
        "Classification",
        "Clustering",
        "Dimensionality Reduction",
        "Regression"
      ],
      correctAnswer: "Dimensionality Reduction"
    },
    {
      question: "Which library is commonly used for deep learning in Python?",
      options: [
        "Pandas",
        "Numpy",
        "Matplotlib",
        "TensorFlow"
      ],
      correctAnswer: "TensorFlow"
    },
    {
      question: "In which scenario would you use a Random Forest?",
      options: [
        "For real-time video processing",
        "For feature selection and classification",
        "For text generation",
        "For speech recognition"
      ],
      correctAnswer: "For feature selection and classification"
    },
    {
      question: "What is the purpose of cross-validation in machine learning?",
      options: [
        "To reduce overfitting",
        "To increase the number of features",
        "To split the data into training and test sets",
        "To normalize the dataset"
      ],
      correctAnswer: "To reduce overfitting"
    },
    {
      question: "Which technique is used for natural language generation?",
      options: [
        "K-Means Clustering",
        "Markov Chains",
        "Linear Regression",
        "Apriori Algorithm"
      ],
      correctAnswer: "Markov Chains"
    },
    {
      question: "What is the primary purpose of using dropout in neural networks?",
      options: [
        "To increase the training time",
        "To reduce the model complexity",
        "To prevent overfitting",
        "To improve the accuracy"
      ],
      correctAnswer: "To prevent overfitting"
    }
  ];
  