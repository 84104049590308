import React, { useState } from 'react';
import './FoundationsHero.css';
import { HashLink } from 'react-router-hash-link';
import tick from '../../../assets/smallAssets/tick.png';
import rocket from "../../../assets/smallAssets/rocket.png";
import { useLocation  } from 'react-router-dom';
const FoundationsHero = ({ course, title, foundationsHeroList, video }) => {
    const [isVideoFullScreen, setIsVideoFullScreen] = useState(false);
    const videoId = 'qIvQ7t1coPM';
    const location = useLocation();
    const handleVideoClick = () => {
        setIsVideoFullScreen(true);
    };

    const handleCloseVideo = () => {
        setIsVideoFullScreen(false);
    };

    return (
        <div className="beginner-hero-wrapper">
            <section className="beginner-hero-section">
                <div className="beginner-hero-content">
                    <p className='tag-line'><span className='highlight'>Learnfinity:</span> Discover, Master, Excel.</p>
                    <h1>{title}</h1>
                    <ul className="beginner-hero-bullets">
                        {foundationsHeroList.map((list, index) => (
                            <li key={index}>
                                <img width="60px" src={tick} alt="check icon" /> {list}
                            </li>
                        ))}
                    </ul>

                    <div className="beginner-hero-buttons">
                        <HashLink to="#module-1" smooth>
                            <button className="btn-primary">See What's Inside</button>
                        </HashLink>
                        <HashLink to={`${location.pathname}/#pricing`} smooth>
                            <button className="btn-secondary">Start Learning Now <img width="28px" className="rocket" src={rocket} alt="rocket"/></button>
                        </HashLink>
                    </div>
                </div>
            </section>

            {video && (
                
                <div
                    className={`beginner-hero-video-container ${isVideoFullScreen ? 'fullscreen' : ''}`}
                    onClick={handleVideoClick}
                >
                    <p className="video-description highlight">
                        Watch how one of our students masters arrays!
                    </p>
                    <iframe
                        width={isVideoFullScreen ? '100%' : '720'}
                        height={isVideoFullScreen ? '100%' : '405'}
                        src={`https://www.youtube.com/embed/${videoId}`}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                    
                    {isVideoFullScreen && (
                        <button className="close-video" onClick={handleCloseVideo}>
                            Close
                        </button>
                    )}
                </div>
            )}
        </div>
    );
}

export default FoundationsHero;
