import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from '../pages/Home/Home';
import FreeGuide from "../components/free-guide/FreeGuide";
import WhyUs from "../components/whyUs/WhyUs";
import PrivacyPolicy from "../components/Legal/PrivacyPolicy";
import ModuleBreakdown from "../components/ModuleBreakdown/ModuleBreakdown";
import Disclaimer from '../components/Legal/Disclaimer';
import TermsAndConditions from '../components/Legal/TermsAndConditions';
import CookiePolicy from '../components/Legal/CookiePolicy';
import ScrollPageToTop from './ScrollPageToTop';
import LoadingPage from '../components/smallComponents/LoadingPage/LoadingPage';
import Pricing from '../components/pricing/Pricing';
import Events from '../components/Events/Events';
import CareerTips from '../components/smallComponents/CareerTips/CareerTips';
import Articles from '../components/smallComponents/Articles/Articles';
import Foundations from '../components/courses/Foundations/Foundations';
import FullStack from '../components/courses/FullStack/FullStack';
import Advanced from '../components/courses/Advanced/Advanced';
import MagicOfCSSGrid from '../components/smallComponents/Articles/MagicOfCSSGrid/MagicOfCSSGrid';

const Index = () => {
    return (
        <>
            <ScrollPageToTop />
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/home' element={<Home />} />
                <Route path='/why-us' element={<WhyUs />} />
                <Route path="/pricing/:courseId" element={<Pricing />} />
                <Route path='/free-guide' element={<FreeGuide />} />
                <Route path='/career-tips' element={<CareerTips />} />
                <Route path='/articles' element={<Articles />} />
                <Route path="/articles/:id" element={<MagicOfCSSGrid />} />
                <Route path='/events' element={<Events />} />
                <Route path="/foundations" element={<Foundations />} />
                <Route path="/full-stack" element={<FullStack />} />
                <Route path="/advanced" element={<Advanced />} />
                <Route path='/privacy_policy' element={<PrivacyPolicy />} />
                <Route path='/disclaimer' element={<Disclaimer />} />
                <Route path='/cookie-policy' element={<CookiePolicy />} />
                <Route path='/terms-conditions' element={<TermsAndConditions />} />
                <Route path='/module-breakdown' element={<ModuleBreakdown />} />
                <Route path="/loading" element={<LoadingPage />} />
            </Routes>
        </>
    )
}

export default Index;
