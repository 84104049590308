import { FaCode, FaServer, FaDatabase, FaShieldAlt, FaCloud, FaRobot, FaLaptopCode, FaChartLine, FaSyncAlt } from 'react-icons/fa';

export const foundationsAudience = [
  {
    imgSrc: require('../assets/stockImages/avatar1.png'),
    icon: <FaLaptopCode />,
    title: 'Complete Beginners',
    description: 'If you have no prior experience in technology, this course is designed for you. Start from scratch and gain a solid foundation in tech, with easy-to-follow lessons that build your skills step by step.',
  },
  {
    imgSrc: require('../assets/stockImages/avatar2.png'),
    icon: <FaChartLine />,
    title: 'Enhance Your Skills',
    description: 'Already familiar with some basic tech concepts but want to deepen your knowledge? This course will help you expand your skills, learn advanced techniques, and apply them to real-world scenarios.',
  },
  {
    imgSrc: require('../assets/stockImages/avatar5.png'),
    icon: <FaSyncAlt />,
    title: 'Career Changers',
    description: 'Looking to switch to a tech career? This course offers a comprehensive roadmap to transition smoothly into the tech industry, equipping you with the skills and confidence to succeed in your new career.',
  },
];

export const fullStackAudience = [
  {
    imgSrc: require('../assets/stockImages/avatar1.png'),
    icon: <FaCode />,
    title: 'Intermediate Coders',
    description: 'If you have a basic understanding of coding and want to advance your programming skills, this course will help you master more complex techniques and improve your software development proficiency.',
  },
  {
    imgSrc: require('../assets/stockImages/avatar2.png'),
    icon: <FaServer />,
    title: 'Full-Stack Developers',
    description: 'For those who want to excel in both front-end and back-end development, this course offers in-depth training on creating robust, scalable, and full-fledged web applications.',
  },
  {
    imgSrc: require('../assets/stockImages/avatar5.png'),
    icon: <FaDatabase />,
    title: 'Data & Security Enthusiasts',
    description: 'Interested in managing data and ensuring its security? This course covers advanced data structures, database management, and cybersecurity practices to protect and manage data efficiently.',
  },
];

export const advancedAudience = [
  {
    imgSrc: require('../assets/stockImages/avatar1.png'),
    icon: <FaShieldAlt />,
    title: 'Aspiring Cybersecurity Experts',
    description: 'This course is ideal if you aim to specialize in cybersecurity. Learn advanced techniques to protect systems, networks, and data from increasingly sophisticated digital threats.',
  },
  {
    imgSrc: require('../assets/stockImages/avatar2.png'),
    icon: <FaCloud />,
    title: 'Cloud Computing Professionals',
    description: 'For those looking to master cloud technologies, this course offers comprehensive training on cloud platforms, services, and deployment strategies essential for modern cloud computing.',
  },
  {
    imgSrc: require('../assets/stockImages/avatar5.png'),
    icon: <FaRobot />,
    title: 'AI & Machine Learning Enthusiasts',
    description: 'If you’re fascinated by artificial intelligence and machine learning, this course will take you deep into developing intelligent systems, from basic algorithms to advanced AI applications.',
  },
];


