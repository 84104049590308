import './App.css';
import { Analytics } from '@vercel/analytics/react';
import Footer from './components/footer/Footer';
import Navbar from './components/navbar/NavBar';
import RouteHandler from './routes';
import { BrowserRouter } from 'react-router-dom';
import { AppProvider } from './contexts/AppContext';
import LaunchBanner from "./components/launchBanner/LaunchBanner";
// import SlideMenu from './components/slideMenu/SlideMenu';

function App() {


  return (
    <BrowserRouter>
      <Analytics />
      <AppProvider>
        <div className='FullScreen'>
          <Navbar />
            {/* <LaunchBanner /> */}
          {/* <SlideMenu /> */}
          <div style={{ minHeight: '100vh' }}>
            <RouteHandler />
          </div>
          <Footer />
        </div>
      </AppProvider>
    </BrowserRouter>
  );
}

export default App;
