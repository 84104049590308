import React, { useState } from 'react';
import './LearnfinityCourseGuide.css';
import { techCourses } from '../../data/CoursesData';
import { FaLaptopCode, FaCode, FaCogs } from "react-icons/fa";
import { priceTagSvg } from '../../assets/svg/svg';
import { Helmet } from 'react-helmet';

const iconComponents = {
  FaLaptopCode: <FaLaptopCode />,
  FaCode: <FaCode />,
  FaCogs: <FaCogs />
};

const CourseModal = ({ children, onClose }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="modal-close" onClick={onClose}>X</button>
        {children}
      </div>
    </div>
  );
};

const LearnfinityCourseGuide = () => {
  const [selectedCourse, setSelectedCourse] = useState(null);

  const handleCourseClick = (course) => {
    if (course.buttonText === 'Coming Soon') {
      setSelectedCourse(course);
    } else {
      const courseLink = course.title.split(' ')[0].toLowerCase();
      window.location.href = `/loading?target=${courseLink}`;
    }
  };


  const handleCloseModal = () => {
    setSelectedCourse(null);
  };

  return (
    <>
      <div className='course-guide-container' id='courses'>
        <h1>Unlock Your <span className='highlight'>True Potential</span> with Learnfinity's Expert Mentoring</h1>
        <div className="course-cards-container">
          {techCourses.map((course, index) => (
            <div
              className={`course-card ${course.mostPopular ? 'most-popular' : ''}`}
              key={index}
              onClick={() => handleCourseClick(course)}
              style={{ cursor: course.buttonText === 'Coming Soon' ? 'pointer' : 'pointer' }}
            >
              {course.mostPopular && <div className="most-popular-tag">Most Popular</div>}
              <img src={course.imgSrc} alt={course.title} className="course-image" />
              <div className="course-info">
                <h2 className="course-title">{course.title}</h2>
                {course.instructor && <p className="course-instructor">{course.instructor} / {course.category}</p>}
                {course.price && (
                  <div className="course-price-container">
                    {course.newTag && <span className="new-tag">NEW</span>}
                    <span className="price-tag">{priceTagSvg}</span>
                    <span className="highlight course-price">{course.price}</span>
                    <span className="course-icon">{iconComponents[course.icon]}</span>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
        {selectedCourse && (
          <CourseModal onClose={handleCloseModal}>
            <h2>{selectedCourse.title}</h2>
            <ul>
              {selectedCourse.features.map((feature, index) => (
                <li key={index} className="modal-feature-item">
                  <img style={{ paddingRight: '0.2rem', marginBottom: '-0.2rem' }} width="30px" height="30px" src={feature.image} alt={feature.image} />
                  <span>{feature.feature}</span>
                </li>
              ))}
            </ul>
          </CourseModal>
        )}
      </div>
    </>
  );
}

export default LearnfinityCourseGuide;
