import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import './CareerPathways.css';

const CareerPathways = () => {
  const [entryLevel, setEntryLevel] = useState(0);
  const [midLevel, setMidLevel] = useState(0);
  const [seniorLevel, setSeniorLevel] = useState(0);
  const [isCounting, setIsCounting] = useState(false);

  const { ref, inView } = useInView({
    triggerOnce: true, 
    threshold: 0.1, 
  });

  useEffect(() => {
    if (inView && !isCounting) {
      const startCount = (setFunction, endValue) => {
        let start = 0;
        const duration = Math.floor(2000 / endValue);
        const timer = setInterval(() => {
          start += 1;
          setFunction(start);
          if (start === endValue) clearInterval(timer);
        }, duration);
      };
      startCount(setEntryLevel, 35);
      startCount(setMidLevel, 55);
      startCount(setSeniorLevel, 70);
      setIsCounting(true);
    }
  }, [inView, isCounting]);

  return (
    <div className="career-pathway" ref={ref}>
      <h2>Your <span className='highlight'>career</span> pathway</h2>
      <p className='highlight'>After finishing our course you will have the skills to advance your career quickly and earn competitive salaries.</p>
      <div className="pathway-container">
        <div className="pathway-card">
          <div className="pathway-arc">
            <span>£{entryLevel}K</span>
          </div>
          <h3 className='highlight'>Average starting salary</h3>
          <h4>Types of Entry Level Roles</h4>
          <ul>
            <li>Tech/Dev Support</li>
            <li>Jnr Full-Stack Developer</li>
            <li>Q/A Tester</li>
            <li>Front-End Developer</li>
          </ul>
        </div>
        
        <div className="pathway-card">
          <div className="pathway-arc">
            <span>£{midLevel}K</span>
          </div>
          <h3 className='highlight'>3-5 years experience</h3>
          <h4>Types of Mid-Senior Roles</h4>
          <ul>
            <li>Full-Stack Developer</li>
            <li>Python Developer</li>
            <li>.net Developer</li>
            <li>Senior Technical Support</li>
          </ul>
        </div>
        
        <div className="pathway-card">
          <div className="pathway-arc">
            <span>£{seniorLevel}K</span>
          </div>
          <h3 className='highlight'>With 5+ years experience</h3>
          <h4>Types of Senior Roles</h4>
          <ul>
            <li>Senior Software Developer</li>
            <li>Software Developer Manager</li>
            <li>Data Analyst</li>
            <li>IT Architect</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CareerPathways;
