import React, { useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import './LoadingPage.css';
import logo from '../../../assets/logos/learnfinity-logo.png';

const LoadingPage = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const target = searchParams.get('target');

    useEffect(() => {
        const timer = setTimeout(() => {
        window.location.replace(target);
        }, 1000);

        return () => clearTimeout(timer);
    }, [target]);
  
    return (
      <div id="loading-indicator">
        <img src={logo} alt="Loading..." className="loading-logo" />
        <NavLink to={`?course=${target}`} id="redirect-link" style={{ display: 'none' }}></NavLink>
      </div>
    );
  };

export default LoadingPage;
