import React from 'react';
import './TechCompanies.css';

const TechCompanies = ({title}) => {
    return (
        <div className="tech-companies-container">
            <p>{title}</p>
            <div className="company-logos">
                <img src={require('../../assets/techCompanies/google.png')} alt="Google" />
                <img src={require('../../assets/techCompanies/microsoft.png')} width={'100%'} alt="Microsoft" />
                <img src={require('../../assets/techCompanies/ibm.png')} alt="IBM" />
                <img src={require('../../assets/techCompanies/apple.png')} alt="Apple" />
                <img src={require('../../assets/techCompanies/amazon.png')} alt="Amazon" />
                <img src={require('../../assets/techCompanies/facebook.png')} width={"100%"} alt="Facebook" />
                <img src={require('../../assets/techCompanies/hp.png')} alt="HP" />
                <img src={require('../../assets/techCompanies/dell.png')} alt="Dell" />
                <img src={require('../../assets/techCompanies/linkedin.png')} alt="LinkedIn" />
                <img src={require('../../assets/techCompanies/tesla.png')} alt="Tesla" />
                <img src={require('../../assets/techCompanies/expedia.png')} alt="Expedia" />
                <img src={require('../../assets/techCompanies/oracle.png')} alt="Oracle" />
                <img src={require('../../assets/techCompanies/x.png')} alt="X" />
                <img src={require('../../assets/techCompanies/netflix.png')} alt="Netflix" />
            </div>
        </div>
    );
}

export default TechCompanies;
