export const general_faqs = [
    {
        question: "Who teaches courses on Learnfinity?",
        answer: "Courses on Learnfinity are taught by industry experts with extensive experience in their respective fields."
    },
    {
        question: "Why is Learnfinity different from other platforms with online courses?",
        answer: "Learnfinity provides a unique blend of high-quality video content, interactive elements, and personalized mentoring, ensuring a comprehensive learning experience."
    },
    {
        question: "Are payments on Learnfinity safe?",
        answer: "Yes, payments on Learnfinity are processed securely using industry-standard encryption methods."
    },
    {
        question: "Are there in-course payments and fees?",
        answer: "There are no hidden fees or in-course payments. The course price listed is the final price."
    },
    {
        question: "Do I get a certificate after completing a course?",
        answer: "Yes, you will receive a certificate of completion after successfully finishing a course."
    },
    {
        question: "How can I access my Learnfinity courses?",
        answer: "You can access your courses from your Learnfinity dashboard after logging into your account."
    },
    {
        question: "Can I interact with instructors on Learnfinity?",
        answer: "Yes, you can interact with instructors through Q&A sessions, discussion forums, and direct messaging within the Learnfinity platform."
    }
];

export const beginnerCourseFAQ = [
    {
        question: "How long does the course take?",
        answer: "The beginner course comprises four modules, totaling approximately 26 hours of content. Each module includes various chapters focusing on different IT and programming topics."
    },
    {
        question: "What are the prerequisites for this course?",
        answer: "No prior experience is necessary. This course is designed for complete beginners who are interested in starting their journey into IT and programming."
    },
    {
        question: "Is the course only about theory?",
        answer: "No, the course includes both theoretical and practical aspects. You'll work on hands-on projects, coding challenges, and real-world tasks to apply the concepts you learn."
    },
    {
        question: "How is the course structured?",
        answer: "The course is divided into four modules: Introduction To IT and Programming, Fundamentals of Programming, Introduction to Databases and Web Development, and Advanced Software Engineering Topics. Each module is broken down into several chapters covering specific topics."
    },
    {
        question: "What topics are covered in the course?",
        answer: "The course covers a wide range of topics including Python basics, control structures, databases, web development (HTML, CSS, JavaScript), cybersecurity, cloud computing, AI, and career development."
    },
    {
        question: "Are there any hidden fees within the course?",
        answer: "No, there are no hidden fees. You gain all benefits and features with the one-time payment, unlocking the entire course content."
    },
    {
        question: "What do I need for completing the course?",
        answer: "All you need is a laptop and internet access. There is no need to purchase any additional software or hardware."
    },
    {
        question: "Do I get a certificate?",
        answer: "Yes, after completing all the modules and quizzes, you will receive a certificate to showcase your newly acquired skills."
    },
    {
        question: "Do you offer support during the course?",
        answer: "Yes, our mentors are available for weekly assessments, Q&A sessions, and final project reviews to provide feedback and guidance."
    },
    {
        question: "Do you offer discounts for students and least developed countries?",
        answer: "Yes, we offer discounts for students and individuals from countries with low purchasing power. Contact us via our Instagram account or send an email to support@learnfinity.co.uk for more information."
    }
];

export const advancedCourseFAQ = [
    {
        question: "How long does the advanced course take?",
        answer: "The advanced course comprises seven modules, totaling approximately 57 hours of content. Each module delves into various advanced programming and tech topics."
    },
    {
        question: "What are the prerequisites for this course?",
        answer: "A solid understanding of programming basics, preferably with experience in Python and web development, is recommended to get the most out of this advanced course."
    },
    {
        question: "Is the course only about theory?",
        answer: "No, the course includes both theoretical and practical aspects. You'll work on hands-on projects, advanced coding challenges, and real-world tasks to apply the concepts you learn."
    },
    {
        question: "How is the course structured?",
        answer: "The course is divided into seven modules: Foundations of Advanced Programming, Full Stack Web Development, Data Science and Machine Learning, DevOps and Cloud Computing, Advanced Cybersecurity, Career Development and Job Readiness, and Capstone Project and Presentation. Each module is broken down into several chapters covering specific topics."
    },
    {
        question: "What topics are covered in the course?",
        answer: "The course covers a wide range of advanced topics including Python, object-oriented and functional programming, web development, data science, machine learning, DevOps, cloud computing, cybersecurity, and career development."
    },
    {
        question: "Are there any hidden fees within the course?",
        answer: "No, there are no hidden fees. You gain all benefits and features with the one-time payment, unlocking the entire course content."
    },
    {
        question: "What do I need for completing the course?",
        answer: "All you need is a laptop and internet access. There is no need to purchase any additional software or hardware."
    },
    {
        question: "Do I get a certificate?",
        answer: "Yes, after completing all the modules and quizzes, you will receive a certificate to showcase your newly acquired advanced tech skills."
    },
    {
        question: "Do you offer support during the course?",
        answer: "Yes, our mentors are available for weekly assessments, Q&A sessions, and final project reviews to provide feedback and guidance."
    },
    {
        question: "Do you offer discounts for students and least developed countries?",
        answer: "Yes, we offer discounts for students and individuals from countries with low purchasing power. Contact us via our Instagram account or send an email to support@learnfinity.co.uk for more information."
    }
];

export const eliteCourseFAQ = [
    {
        question: "How long does the Elite Tech Innovator Program take?",
        answer: "The Elite Tech Innovator Program comprises seven modules, totaling approximately 68 hours of content. Each module dives deep into various advanced tech topics and leadership skills."
    },
    {
        question: "What are the prerequisites for this course?",
        answer: "A solid understanding of programming and basic knowledge of web development, data science, and cybersecurity is recommended to get the most out of this elite program."
    },
    {
        question: "Is the course only about theory?",
        answer: "No, the course includes both theoretical knowledge and practical applications. You will engage in hands-on projects, advanced coding challenges, and real-world tasks to apply the concepts you learn."
    },
    {
        question: "How is the course structured?",
        answer: "The course is divided into seven modules: Advanced Programming Techniques, Emerging Technologies, Data Science and Machine Learning, Cloud and DevOps Mastery, Cybersecurity and Ethical Hacking, Leadership and Innovation in Tech, and Final Capstone Project. Each module consists of several chapters covering specific topics."
    },
    {
        question: "What topics are covered in the course?",
        answer: "The course covers a wide range of advanced topics including Python, JavaScript, asynchronous programming, blockchain, quantum computing, AR/VR, data science, machine learning, DevOps, cloud computing, cybersecurity, and leadership skills."
    },
    {
        question: "Are there any hidden fees within the course?",
        answer: "No, there are no hidden fees. You gain all benefits and features with the one-time payment, unlocking the entire course content."
    },
    {
        question: "What do I need for completing the course?",
        answer: "All you need is a laptop and internet access. There is no need to purchase any additional software or hardware."
    },
    {
        question: "Do I get a certificate?",
        answer: "Yes, after completing all the modules and quizzes, you will receive a certificate to showcase your newly acquired elite tech skills."
    },
    {
        question: "Do you offer support during the course?",
        answer: "Yes, our mentors are available for weekly assessments, Q&A sessions, and final project reviews to provide feedback and guidance."
    },
    {
        question: "Do you offer discounts for students and least developed countries?",
        answer: "Yes, we offer discounts for students and individuals from countries with low purchasing power. Contact us via our Instagram account or send an email to support@learnfinity.co.uk for more information."
    }
];
