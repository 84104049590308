import React, { useState } from 'react';
import './AdvancedHero.css';
import { HashLink } from 'react-router-hash-link';
import tick from '../../../assets/smallAssets/tick.png';
import rocket from "../../../assets/smallAssets/rocket.png";
import DSMLQuiz from '../../DSMLQuiz/DSMLQuiz';
const AdvancedHero = ({ course, title, advancedHeroList }) => {
    return (
        <div className="elite-hero-wrapper">
            <section className="elite-hero-section">
                <div className="elite-hero-content">
                    <p className='tag-line'><span className='highlight'>Learnfinity:</span> Take Your Skills to the Next Level.</p>
                    <h1>{title}</h1>
                    <ul className="advanced-hero-bullets">
                        {advancedHeroList.map((list, index) => (
                            <li key={index}>
                                <img width="60px" src={tick} alt="check icon" /> {list}
                            </li>
                        ))}
                    </ul>
                    <div className="elite-hero-buttons">
                        <HashLink to="#module-1" smooth>
                            <button className="btn-primary">See What's Inside</button>
                        </HashLink>
                        <HashLink to={`${location.pathname}/#pricing`} smooth>
                            <button className="btn-secondary">Start Learning Now <img width="28px" className="rocket" src={rocket} alt="rocket"/></button>
                        </HashLink>
                    </div>
                </div>
            </section>
            <DSMLQuiz />
        </div>
    );
}

export default AdvancedHero;
