import React from 'react';
import './DevOpsToolsShowcase.css';
import { tools } from '../../data/devopsTools';
const DevOpsToolsShowcase = () => {
  return (
    <div className="tools-container">
     <h2>Master the Core Tools of <span className='highlight'>DevOps & Cloud Computing</span></h2>
     <p className='highlight'>Gain hands-on experience with the industry-leading tools that top companies rely on.</p>
      <div className="tools-grid">
        {tools.map((tool, index) => (
          <div key={index} className="tool-card">
            <div className="card-inner">
              <div className="card-front">
                <img src={require(`../../assets/devops/${tool.image}`)} alt={tool.name} />
                <h3 className='highlight'>{tool.name}</h3>
                <p>{tool.description}</p>
              </div>
              <div className="card-back">
                <h3 className='highlight'>{tool.name}</h3>
                <p>{tool.details}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      
    </div>
  );
};

export default DevOpsToolsShowcase;
