import React, { useState } from 'react';
import { careerTips } from "../../../data/careerTips";
import career from "../../../assets/stockImages/career.png";
import { Helmet } from 'react-helmet';

const CareerTips = () => {
    const [searchTerm, setSearchTerm] = useState('');

    const filteredTips = careerTips.filter(tip => 
        tip.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        tip.description.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <>
            <Helmet>
                <title>Career Tips for Tech Professionals - Learnfinity</title>
                <meta name="description" content="Access valuable career tips from industry experts. Learnfinity offers insights to help you navigate your tech career journey successfully. Get tips and advice tailored for the tech industry." />
                <meta property="og:title" content="Career Tips for Tech Professionals - Learnfinity" />
                <meta property="og:description" content="Access valuable career tips and strategies to excel in your tech career." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.learnfinity.co.uk/career-tips" />
                <meta property="og:image" content="https://www.learnfinity.co.uk/images/lf.png" />
            </Helmet>
            <div className="container mx-auto p-6">
                <div className="flex justify-center mb-8">
                    <img src={career} alt="Career" className="max-w-xs h-auto" />
                </div>
                <h1 className="text-5xl font-bold text-center mb-10 highlight leading-tight">Career and Learning Tips</h1>
                <div className="max-w-md mx-auto">
                    <p className="text-center text-lg text-gray-600 mb-8 highlight">
                        A collection of valuable career tips that you wish you knew when you started your journey in the tech industry.
                    </p>
                    <div className="mb-6 flex justify-center">
                        <input 
                            type="text" 
                            placeholder="Search through 30 tips" 
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400"
                        />
                    </div>
                </div>
                <div className="flex flex-col gap-6">
                    {filteredTips.map((tip, index) => (
                        <div key={index} className="relative bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
                            {tip.icon}
                            <h3 className="text-xl font-semibold text-blue-600 mb-4 relative highlight">{tip.title}</h3>
                            <p className="text-gray-700 relative">{tip.description}</p>
                        </div>
                    ))}
                    {filteredTips.length === 0 && (
                        <div className="text-center text-gray-500">No tips found.</div>
                    )}
                </div>
            </div>
        </>
    );
};

export default CareerTips;
