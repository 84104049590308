import React, { useState } from 'react';
import './DSMLQuiz.css';
import { eliteModulesQuizQuestions } from '../../data/quizQuestions';

const DSMLQuiz = () => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [score, setScore] = useState(0);
  const [showResult, setShowResult] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);

  const handleAnswerClick = (option) => {
    setSelectedAnswer(option);
  };

  const handleNextQuestion = () => {
    if (selectedAnswer === eliteModulesQuizQuestions[currentQuestionIndex].correctAnswer) {
      setScore(score + 1);
    }

    setSelectedAnswer(null);

    if (currentQuestionIndex < eliteModulesQuizQuestions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      setShowPopUp(true);
      setShowResult(true);
    }
  };

  const handleRestartQuiz = () => {
    setCurrentQuestionIndex(0);
    setSelectedAnswer(null);
    setScore(0);
    setShowResult(false);
  };

  const handleClosePopup = () => {
    setShowPopUp(false);
  };

  return (
    <div className="quiz-container">
      <h2>Test Your <span className='highlight'>Data Science and Machine Learning</span> Knowledge</h2>
      {!showResult ? (
        <div className="quiz-content">
          <h3 className='highlight'>{eliteModulesQuizQuestions[currentQuestionIndex].question}</h3>
          <ul className="quiz-options">
            {eliteModulesQuizQuestions[currentQuestionIndex].options.map((option, index) => (
              <li 
                key={index} 
                onClick={() => handleAnswerClick(option)} 
                className={selectedAnswer === option ? 'selected' : ''}
              >
                {option}
              </li>
            ))}
          </ul>
          <div className="text-center"> {/* Center the button */}
            <button className="btn quiz-button" onClick={handleNextQuestion} disabled={!selectedAnswer}>
              {currentQuestionIndex < eliteModulesQuizQuestions.length - 1 ? 'Next' : 'See Results'}
            </button>
          </div>
        </div>
      ) : (
        <div className="quiz-result text-center"> {/* Center the result and button */}
          <h3 className='highlight'>Your Score: {score} / {eliteModulesQuizQuestions.length}</h3>
          <button className="quiz-button" onClick={handleRestartQuiz}>Restart Quiz</button>
        </div>
      )}
      {showPopUp && (
        <div className="completion-popup">
          <div className="popup-content">
            <h3 >Well Done!</h3>
            <p >You have successfully completed the quiz.</p>
            <p >Your Score: {score} / {eliteModulesQuizQuestions.length}</p>
            <div className="text-center">
              <button className="quiz-button" onClick={handleClosePopup}>Close</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DSMLQuiz;
