import React from 'react';
import '../CourseDetails.css';
import { advancedModules } from '../../../data/fullStackModules';
import ModuleBreakdown from '../../ModuleBreakdown/ModuleBreakdown';
import PaymentMethods from '../../PaymentMethods/PaymentMethod';
import FrequentlyAsked from '../../faq/FrequentlyAsked';
import { Slide } from 'react-awesome-reveal';
import { advancedCourseFAQ } from '../../../data/FAQs';
import { HashLink } from 'react-router-hash-link';
import CourseAudience from '../../CourseAudience/CourseAudience';
import { useLocation  } from 'react-router-dom';
import { fullStackModules,fullStackHeroList } from '../../../data/fullStackModules';
import TechChallenges from '../../TechChallenges/TechChallenges';
import rocket from "../../../assets/smallAssets/rocket.png";
import Pricing from '../../pricing/Pricing';
import lectures from "../../../assets/materials/lectures.png";
import FullStackHero from '../../HeroSection/FullStackHero/FullStackHero';
import { Helmet } from 'react-helmet';

const FullStack = () => {  
  const location = useLocation();
  const course = location.pathname.replace('/', '');
  const title = 'Expert-Guided 1-1 Mentorship for Advanced Professionals';  
  const description = 'Elevate your tech career with advanced full-stack development training from industry experts.';
  const cleanPathname = location.pathname.replace(/\/+$/, '');
  const metaTitle ='Learnfinity Full Stack: Advanced Mentorship for Tech Professionals';
  const metaDescription = `Master full stack development with Learnfinity's advanced course. Get personalized mentorship, tackle real-world projects, and enhance your skills for a successful tech career.`
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={metaDescription} />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:image" content="https://www.learnfinity.co.uk/images/lf.png" />
        <meta property="og:url" content="https://www.learnfinity.co.uk/full-stack" />
        <meta property="og:type" content="website" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:title" content={metaTitle} />
        <meta property="twitter:description" content={metaDescription} />
        <meta property="twitter:image" content="https://www.learnfinity.co.uk/path/to/image.jpg" />
      </Helmet>
      {/* <HeroSection course={course} title={title} subtitle={subtitle} video={false}/> */}
      <FullStackHero course={course} title={title} fullStackHeroList={fullStackHeroList} />
      <div id="module-1">
        <ModuleBreakdown courseName={'fullStackModules'} course={fullStackModules} />
      </div>
      <TechChallenges />
      <div id="start-learning">
        <CourseAudience courseAudience={'fullStackModules'} />
      </div>
      <div className="course-buttons">          
        <HashLink to={`${cleanPathname}#pricing`} smooth>
          <button className="course-button">
            Start Learning Now
            <img width="30px" className="rocket" src={rocket} alt="rocket"/>
          </button>
        </HashLink>
      </div>
      <PaymentMethods />
      <div id="pricing">
        <Pricing courseName={'full-stack'} images={lectures}/>
      </div>
      <FrequentlyAsked color={'#0e346d'} faqs={advancedCourseFAQ} id="full-stack-faq"/>
    </>
  );
};

export default FullStack;
