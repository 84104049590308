import React from 'react';
import './Policies.css';
import { Helmet } from 'react-helmet';

const Disclaimer = () => (
    <div className="policy-container">
        <Helmet>
            <title>Disclaimer - Learnfinity</title>
            <meta name="description" content="Learnfinity's Disclaimer outlines the limitations of liability and information provided on our website." />
            <meta property="og:title" content="Disclaimer - Learnfinity" />
            <meta property="og:description" content="Learnfinity's Disclaimer outlines the limitations of liability and information provided on our website." />
            <meta property="og:type" content="article" />
            <meta property="og:url" content="https://www.learnfinity.co.uk/disclaimer" />
            <meta property="og:image" content="https://www.learnfinity.co.uk/images/lf.png" />
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:title" content="Disclaimer - Learnfinity" />
            <meta property="twitter:description" content="Learnfinity's Disclaimer outlines the limitations of liability and information provided on our website." />
            <meta property="twitter:image" content="https://www.learnfinity.co.uk/images/lf.png" />
        </Helmet>
        <h1>Disclaimer</h1>
        <p>Last updated: 07/07/2023</p>
        
        <h2>Interpretation and Definitions</h2>
        <p>
            The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
        </p>
        <p>
            For the purposes of this Disclaimer:
            <br />Company (referred to as either "the Company", "We", "Us" or "Our" in this Disclaimer) refers to Learnfinity.
            <br />Service refers to the Website.
            <br />You means the individual accessing the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.
            <br />Website refers to Learnfinity, accessible from https://learnfinity.co.uk/
        </p>
        
        <h2>Disclaimer</h2>
        <p>
            The information contained on the Service is for general information purposes only. The Company assumes no responsibility for errors or omissions in the contents of the Service.
        </p>
        <p>
            In no event shall the Company be liable for any special, direct, indirect, consequential, or incidental damages or any damages whatsoever, whether in an action of contract, negligence or other tort, arising out of or in connection with the use of the Service or the contents of the Service. The Company reserves the right to make additions, deletions, or modifications to the contents on the Service at any time without prior notice.
        </p>
        <p>
            The Company does not warrant that the Service is free of viruses or other harmful components.
        </p>

        <h2>External Links Disclaimer</h2>
        <p>
            The Service may contain links to external websites that are not provided or maintained by or in any way affiliated with the Company. Please note that the Company does not guarantee the accuracy, relevance, timeliness, or completeness of any information on these external websites.
        </p>

        <h2>Errors and Omissions Disclaimer</h2>
        <p>
            The information given by the Service is for general guidance on matters of interest only. Even if the Company takes every precaution to ensure that the content of the Service is both current and accurate, errors can occur. Plus, given the changing nature of laws, rules, and regulations, there may be delays, omissions, or inaccuracies in the information contained on the Service.
        </p>

        <h2>Fair Use Disclaimer</h2>
        <p>
            The Company may use copyrighted material which has not always been specifically authorized by the copyright owner. The Company is making such material available for criticism, comment, news reporting, teaching, scholarship, or research.
        </p>
        <p>
            The Company believes this constitutes a "fair use" of any such copyrighted material as provided for in section 107 of the United States Copyright law. If You wish to use copyrighted material from the Service for your own purposes that go beyond fair use, You must obtain permission from the copyright owner.
        </p>

        <h2>Views Expressed Disclaimer</h2>
        <p>
            The Service may contain views and opinions which are those of the authors and do not necessarily reflect the official policy or position of any other author, agency, organization, employer, or company, including the Company.
        </p>

        <h2>No Responsibility Disclaimer</h2>
        <p>
            The information on the Service is provided with the understanding that the Company is not herein engaged in rendering legal, accounting, tax, or other professional advice and services. As such, it should not be used as a substitute for consultation with professional accounting, tax, legal, or other competent advisers.
        </p>

        <h2>"Use at Your Own Risk" Disclaimer</h2>
        <p>
            All information in the Service is provided "as is", with no guarantee of completeness, accuracy, timeliness or of the results obtained from the use of this information, and without warranty of any kind, express or implied, including, but not limited to warranties of performance, merchantability and fitness for a particular purpose. The Company will not be liable to You or anyone else for any decision made or action taken in reliance on the information given by the Service or for any consequential, special or similar damages, even if advised of the possibility of such damages.
        </p>
    </div>
);

export default Disclaimer;
