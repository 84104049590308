import React from 'react';
import './ElevateSkills.css';
import { HashLink } from 'react-router-hash-link';
import ToolsIcons from '../ToolsIcons/ToolsIcons';
import rocket from "../../assets/smallAssets/rocket.png";
import singleFinger from "../../assets/single-finger.png";
const ElevateSkills = ({ animationLineColor, title, titleColor }) => {
    return (
        <section className="elevate-skills">
            <div className="tools-animation-wrapper">
                <img width="30%" height="20%" src={singleFinger} alt={singleFinger} />
            </div>
            <h2 style={{ color: titleColor ? titleColor : 'white' }}>{title}</h2>
            <HashLink to="/#courses" smooth>
                <button className="btn" id="start-learning-button">
                    Start Learning <img className="rocket" src={rocket} alt="rocket" />
                </button>
            </HashLink>
        </section>
    );
};

export default ElevateSkills;
