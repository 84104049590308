import React from 'react';
import './InfiniteTags.css';
import { SubjectTags } from '../../../data/SubjectsData';

export const InfiniteTags = () => {
    return (
        <div className='infinite-tags-container'>
            {/* <h2 className='infinite-heading'>Learn Valuable Topics</h2> */}
            <div className='tag-list'>
                <div className='inner'>
                    {[...SubjectTags, ...SubjectTags].map((tag, index) => (
                        <div key={index} className='tag'>
                            {/* <img src={handleIconSource(tag.value)} alt={tag.value} /> */}
                            {tag.subject}
                        </div>
                    ))}
                    <div className='fade'></div>
                </div>
            </div>
            {/* <p className='infinite-text'>Gain an infinite path of possibilities! Here are just some of the topics that you will be covering in the extensive beginners boot-camp</p> */}
        </div>
    );
};
