export const testimonials = [
    {
        name: 'Harry',
        handle: '@Harry',
        text: `Absolutely mind-blowing! He helps me to understand concepts well by explaining them in different ways that he thinks will help my comprehension of the topic, and often gives an example to follow when doing work.`,
        avatar: require('../assets/stockImages/avatar1.png'),
        flag: require('../assets/flags/england.png'),
    },
    {
        name: 'Omar',
        handle: '@OmarTaky',
        text: `I would like to commend the exceptional teaching prowess of instructors Faisal and Anouar at Learnfinity. Their expertise and passion for their subject matter is inspiring.`,
        avatar: require('../assets/stockImages/avatar2.png'),
        // image: require('../assets/testimonials/omar.png'),
        flag: require('../assets/flags/morocco.png'),
    },
    {
        name: 'Yassin',
        handle: '@Yassin',
        text: `I am currently taking an introductory coding course covering Python, HTML, CSS, and more, and I am incredibly impressed by my teacher, Anwar. His passion for coding and teaching was evident from the start, he breaks down complex concepts into manageable parts and takes practical approach, integrating real-world examples and hands-on projects which makes the learning process enjoyable and relevant. He is always available for extra help and has been super flexible with my timings. I couldn’t recommend this course and Anwar enough!`,
        avatar: require('../assets/stockImages/avatar3.png'),
        image: require('../assets/testimonials/yassin.JPG'),
        flag: require('../assets/flags/morocco.png'),
    },
    {
        name: 'Mubarak',
        handle: '@Mubarak',
        text: `I had zero experience with python before the course and the instructor was both patient enough to work with me.`,
        avatar: require('../assets/stockImages/avatar4.png'),
        image: require('../assets/testimonials/mubarak.JPG'),
        flag: require('../assets/flags/somalia.png'),
    },
    {
        name: 'Nayim',
        handle: '@Nayim',
        text: `Wonderful service and teaching skills. 10/10 Will definitely recommend to anyone who needs academic help.`,
        avatar: require('../assets/stockImages/avatar3.png'),
        flag: require('../assets/flags/morocco.png'),
    }
];
