import { FaLaptopCode, FaTools, FaBug, FaLightbulb, FaProjectDiagram, FaUserTie, FaFileCode, FaBrain, FaPenAlt, FaGlobe, FaChartLine, FaPeopleArrows, FaUsers, FaCogs, FaChartPie,FaGraduationCap, FaRegClock, FaComments, FaClipboardCheck, FaNetworkWired, FaCode, FaSyncAlt, FaShieldAlt, FaDatabase, FaCloud, FaRobot, FaHandsHelping, FaFolderOpen, FaCog, FaSearch } from 'react-icons/fa';

export const careerTips = [
    {
        title: "Master the Fundamentals",
        description: "Start by building a solid foundation in essential IT skills and programming concepts. This is crucial for any tech role and will set you up for success in more advanced areas.",
        icon: <FaLaptopCode className="text-blue-200 text-6xl opacity-20 absolute top-0 right-0 m-4" />
    },
    {
        title: "Get Hands-On with Tools",
        description: "Familiarize yourself with tools like Python and GitHub, which are widely used by developers worldwide. Practical knowledge of these tools will enhance your coding efficiency.",
        icon: <FaTools className="text-yellow-200 text-6xl opacity-20 absolute top-0 right-0 m-4" />
    },
    {
        title: "Learn to Troubleshoot",
        description: "Develop your problem-solving skills by learning effective research and error-handling techniques. Being able to troubleshoot issues is an essential skill for any developer.",
        icon: <FaBug className="text-red-200 text-6xl opacity-20 absolute top-0 right-0 m-4" />
    },
    {
        title: "Stay Curious and Keep Learning",
        description: "The tech industry is always evolving. Stay curious and continuously learn new programming languages, tools, and technologies to remain competitive in the job market.",
        icon: <FaLightbulb className="text-green-200 text-6xl opacity-20 absolute top-0 right-0 m-4" />
    },
    {
        title: "Build Real-World Projects",
        description: "Apply your skills by working on real-world projects. This not only enhances your learning but also builds your portfolio, making you a more attractive candidate to potential employers.",
        icon: <FaProjectDiagram className="text-purple-200 text-6xl opacity-20 absolute top-0 right-0 m-4" />
    },
    {
        title: "Seek Mentorship",
        description: "Engage with industry experts and seek mentorship to guide your learning journey. Personalized guidance can significantly accelerate your progress and help you avoid common pitfalls.",
        icon: <FaUserTie className="text-indigo-200 text-6xl opacity-20 absolute top-0 right-0 m-4" />
    },
    {
        title: "Prepare for Technical Interviews",
        description: "As you advance, prepare for technical interviews by practicing coding challenges and studying common interview questions. This will boost your confidence and increase your chances of success.",
        icon: <FaFileCode className="text-teal-200 text-6xl opacity-20 absolute top-0 right-0 m-4" />
    },
    {
        title: "Develop Soft Skills",
        description: "Communication, teamwork, and empathy are as important as technical skills in a collaborative work environment.",
        icon: <FaChartLine className="text-orange-200 text-6xl opacity-20 absolute top-0 right-0 m-4" />
    },
    {
        title: "Understand System Design",
        description: "Learning system design is crucial for developing scalable and efficient applications, particularly if you aim for senior roles.",
        icon: <FaNetworkWired className="text-cyan-200 text-6xl opacity-20 absolute top-0 right-0 m-4" />
    },
    {
        title: "Embrace Open Source Contributions",
        description: "Contributing to open-source projects can enhance your coding skills, expand your network, and boost your resume.",
        icon: <FaCode className="text-pink-200 text-6xl opacity-20 absolute top-0 right-0 m-4" />
    },
    {
        title: "Stay Updated with Industry Trends",
        description: "Keep an eye on the latest trends and emerging technologies. Being informed about the industry helps you stay relevant.",
        icon: <FaSyncAlt className="text-gray-200 text-6xl opacity-20 absolute top-0 right-0 m-4" />
    },
    {
        title: "Focus on Data Security",
        description: "Understanding and implementing best practices in cybersecurity is vital in today’s environment.",
        icon: <FaShieldAlt className="text-red-200 text-6xl opacity-20 absolute top-0 right-0 m-4" />
    },
    {
        title: "Understand Databases",
        description: "Knowledge of database management, including SQL and NoSQL, is essential for backend development roles.",
        icon: <FaDatabase className="text-purple-200 text-6xl opacity-20 absolute top-0 right-0 m-4" />
    },
    {
        title: "Learn about Cloud Computing",
        description: "Cloud platforms like AWS and Azure are integral to modern development. Understanding cloud computing can significantly enhance your career prospects.",
        icon: <FaCloud className="text-indigo-200 text-6xl opacity-20 absolute top-0 right-0 m-4" />
    },
    {
        title: "Explore New Areas like AI/ML",
        description: "Artificial Intelligence and Machine Learning are growing fields. Learning AI/ML can significantly boost your career prospects.",
        icon: <FaRobot className="text-green-200 text-6xl opacity-20 absolute top-0 right-0 m-4" />
    },
    {
        title: "Enhance Your Problem-Solving Skills",
        description: "Sharpen your analytical thinking and problem-solving abilities. These are key traits of successful IT professionals.",
        icon: <FaBrain className="text-purple-200 text-6xl opacity-20 absolute top-0 right-0 m-4" />
    },
    {
        title: "Improve Your Technical Writing",
        description: "Clear documentation is vital. Learn to articulate complex technical concepts in a way that's accessible to others.",
        icon: <FaPenAlt className="text-blue-200 text-6xl opacity-20 absolute top-0 right-0 m-4" />
    },
    {
        title: "Expand Your Global Perspective",
        description: "Understanding global markets and cultural nuances can give you an edge in international tech roles.",
        icon: <FaGlobe className="text-green-200 text-6xl opacity-20 absolute top-0 right-0 m-4" />
    },
    {
        title: "Network with Professionals",
        description: "Building a strong network in the tech community can open doors to new opportunities and collaborations.",
        icon: <FaPeopleArrows className="text-indigo-200 text-6xl opacity-20 absolute top-0 right-0 m-4" />
    },
    {
        title: "Develop Team Collaboration Skills",
        description: "Working effectively within a team is crucial in tech. Learn to collaborate and communicate within diverse teams.",
        icon: <FaUsers className="text-teal-200 text-6xl opacity-20 absolute top-0 right-0 m-4" />
    },
    {
        title: "Understand the Business Side of Tech",
        description: "Grasping the business implications of technology decisions can make you more valuable to your organization.",
        icon: <FaCogs className="text-gray-200 text-6xl opacity-20 absolute top-0 right-0 m-4" />
    },
    {
        title: "Learn Project Management",
        description: "Managing projects effectively, understanding timelines, and coordinating with teams are essential skills.",
        icon: <FaChartPie className="text-orange-200 text-6xl opacity-20 absolute top-0 right-0 m-4" />
    },
    {
        title: "Pursue Continuous Education",
        description: "Stay ahead by continuously updating your skills through courses, certifications, and learning new technologies.",
        icon: <FaGraduationCap className="text-red-200 text-6xl opacity-20 absolute top-0 right-0 m-4" />
    },
    {
        title: "Practice Time Management",
        description: "Balancing multiple tasks and managing your time effectively is crucial in tech roles.",
        icon: <FaRegClock className="text-yellow-200 text-6xl opacity-20 absolute top-0 right-0 m-4" />
    },
    {
        title: "Engage in Peer Discussions",
        description: "Joining forums and discussion groups can provide new insights and help you learn from others' experiences.",
        icon: <FaComments className="text-blue-200 text-6xl opacity-20 absolute top-0 right-0 m-4" />
    },
    {
        title: "Set Clear Goals",
        description: "Having clear, actionable goals helps you stay focused and motivated on your learning journey.",
        icon: <FaClipboardCheck className="text-purple-200 text-6xl opacity-20 absolute top-0 right-0 m-4" />
    },
    {
        title: "Research Industry Demands",
        description: "Understand what skills are in demand to better align your learning with market needs.",
        icon: <FaSearch className="text-green-200 text-6xl opacity-20 absolute top-0 right-0 m-4" />
    },
    {
        title: "Refine Your Automation Skills",
        description: "Automation is key in tech. Learn to automate repetitive tasks to increase efficiency.",
        icon: <FaCog className="text-teal-200 text-6xl opacity-20 absolute top-0 right-0 m-4" />
    },
    {
        title: "Volunteer for Tech Initiatives",
        description: "Volunteering for tech projects or events helps you gain practical experience and expand your network.",
        icon: <FaHandsHelping className="text-indigo-200 text-6xl opacity-20 absolute top-0 right-0 m-4" />
    },
    {
        title: "Organize Your Learning Materials",
        description: "Keep your learning resources organized and accessible to streamline your study process.",
        icon: <FaFolderOpen className="text-yellow-200 text-6xl opacity-20 absolute top-0 right-0 m-4" />
    }
];
