export const eventsData = [
    {
        title: "Introduction to Python",
        date: "Every Saturday",
        instructor: "Anwar Abdi",
        snippet: "Explore the basics of Python, including data types, control structures, and functions. This session is perfect for beginners looking to start their programming journey.",
        imgSrc: require('../assets/courseImages/Cover10.png'),
        buttonText: "Enroll Now",
        module: "Beginner Course",
        icon: 'FaLaptopCode',
        sneakPeek: {
            title: "Sneak Peek into 'Your Journey into Technology'",
            description: "This sneak peek will cover foundational concepts such as Python programming, basic cybersecurity practices, and introductory cloud computing—all essential skills for a beginner in tech."
        }
    },
    {
        title: "Mastering JavaScript",
        date: "Every Saturday",
        instructor: "Faisal Bhuyian",
        snippet: "Dive into the modern features of JavaScript with ES6 and beyond. This webinar will cover arrow functions, promises, async/await, and other advanced JavaScript concepts.",
        imgSrc: require('../assets/courseImages/Cover8.png'),
        buttonText: "Enroll Now",
        module: "Advanced Tech Mastery Program",
        icon: 'FaCode',
        sneakPeek: {
            title: "Sneak Peek into 'Advanced Tech Mastery Program'",
            description: "Explore advanced JavaScript features, web development with React.js, and backend development using Node.js. This sneak peek offers a glimpse into the powerful tools you'll master."
        }
    },
    {
        title: "React.js Deep Dive",
        date: "Every Saturday",
        instructor: "Faisal Bhuyian",
        snippet: "This session will take you through the intricacies of React.js, including hooks, state management, and building reusable components. Ideal for developers aiming to enhance their frontend skills.",
        imgSrc: require('../assets/courseImages/Cover9.png'),
        buttonText: "Enroll Now",
        module: "Advanced Tech Mastery Program",
        icon: 'FaCode',
        sneakPeek: {
            title: "Sneak Peek into 'Advanced Tech Mastery Program'",
            description: "Get a detailed preview of React.js, including state management, component lifecycle, and best practices for building scalable web applications."
        }
    },
    {
        title: "Building RESTful APIs with Node.js",
        date: "October 5, 2024",
        instructor: "Faisal Bhuyian",
        comingSoon: true,
        snippet: "Learn how to build robust and scalable RESTful APIs using Node.js and Express. This webinar will guide you through building an API from scratch with hands-on examples.",
        imgSrc: require('../assets/courseImages/Cover7.png'),
        buttonText: "Enroll Now",
        module: "Advanced Tech Mastery Program",
        icon: 'FaCode',
        sneakPeek: {
            title: "Sneak Peek into 'Advanced Tech Mastery Program'",
            img: require('../assets/smallAssets/api.png'),
            description: "Preview how to structure APIs, manage data with MongoDB, and integrate with frontend frameworks like React."
        }
    },
    {
        title: "Cybersecurity Essentials",
        date: "October 12, 2024",
        instructor: "Anwar Abdi",
        comingSoon: true,
        snippet: "Understand the fundamental concepts of cybersecurity, including threat detection, prevention, and securing networks. This session is crucial for anyone entering the tech industry.",
        imgSrc: require('../assets/courseImages/Cover7.png'),
        buttonText: "Enroll Now",
        module: "Elite Tech Innovator Program",
        icon: 'FaCogs',
        sneakPeek: {
            title: "Sneak Peek into 'Elite Tech Innovator Program'",
            img: require('../assets/smallAssets/cybersecurity.png'),
            description: "This preview will cover advanced cybersecurity techniques, cloud computing with AWS and Azure, and a deep dive into artificial intelligence."
        }
    },
    {
        title: "Introduction to Cloud Computing",
        date: "October 19, 2024",
        instructor: "Anwar Abdi",
        comingSoon: true,
        snippet: "Get started with cloud computing, focusing on AWS and Azure platforms. This webinar will cover cloud fundamentals, including deployment models, and cloud services.",
        imgSrc: require('../assets/courseImages/Cover7.png'),
        buttonText: "Enroll Now",
        module: "Elite Tech Innovator Program",
        icon: 'FaCogs',
        sneakPeek: {
            title: "Sneak Peek into 'Elite Tech Innovator Program'",
            img: require('../assets/devops/aws-icon.png'),
            description: "Explore cloud architecture, deployment models, and get hands-on with popular cloud platforms. A must-attend for aspiring cloud engineers."
        }
    }
];
