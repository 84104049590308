import {FaThLarge} from 'react-icons/fa';

export const articlesList = [
    {
        id: 'css-grid',
        title: "The Magic of CSS Grid: Building Responsive Layouts with Ease",
        coverImage: require('../assets/stockImages/css-grid.png'),
        description: "Discover the power of CSS Grid, a revolutionary layout system...",
        icon: <FaThLarge className="text-blue-200 text-6xl opacity-20 absolute top-0 right-0 m-4" />
    },
];
