import React from 'react';
import { Helmet } from 'react-helmet';
import cssGridImage from "../../../../assets/stockImages/css-grid.png";

const MagicOfCSSGrid = () => {
    return (
        <div className="container mx-auto p-6">
            <Helmet>
                <title>The Magic of CSS Grid | Learnfinity</title>
                <meta name="description" content="Learn the power of CSS Grid for building responsive layouts. Explore this article on how CSS Grid simplifies complex web designs with ease. A must-read for web developers." />
                <meta property="og:title" content="The Magic of CSS Grid | Learnfinity" />
                <meta property="og:description" content="Learn the power of CSS Grid for building responsive layouts. Explore this article on how CSS Grid simplifies complex web designs with ease. A must-read for web developers." />
                <meta property="og:image" content="https://www.learnfinity.co.uk/images/lf.png" />
                <meta property="og:url" content="https://www.learnfinity.co.uk/articles/css-grid" />
                <meta name="twitter:title" content="The Magic of CSS Grid | Learnfinity" />
                <meta name="twitter:description" content="Learn the power of CSS Grid for building responsive layouts. Explore this article on how CSS Grid simplifies complex web designs with ease. A must-read for web developers." />
                <meta name="twitter:image" content="https://www.learnfinity.co.uk/path/to/image.jpg" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <div className="flex justify-center mb-8">
                <img src={cssGridImage} alt="CSS Grid" className="w-full max-w-md h-auto" />
            </div>
            <h1 className="text-4xl sm:text-5xl font-bold text-center mb-10 highlight leading-tight">
                The Magic of CSS Grid: Building Responsive Layouts with Ease
            </h1>
            <div className="max-w-full sm:max-w-2xl mx-auto">
                <p className="text-base sm:text-lg text-gray-600 mb-8">
                    Discover the power of CSS Grid, a revolutionary layout system that allows web developers to create complex, responsive designs with just a few lines of code. Learn how CSS Grid simplifies the process of building grid-based layouts and why it’s a game-changer for modern web design.
                </p>

                <h2 className="text-2xl sm:text-3xl font-semibold text-blue-600 mb-6">Understanding the Basics of CSS Grid</h2>
                <p className="text-gray-700 mb-4">
                    CSS Grid operates on two fundamental elements: the grid container and the grid items. The grid container is the parent element that defines the grid context, while the grid items are the child elements arranged within that grid.
                </p>

                <pre className=" p-4 rounded-lg mb-6 overflow-x-auto">
                    <code>
                        {`/* Grid Container Properties */
.container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}

/* Grid Item Properties */
.item {
    grid-column: span 2;
    justify-self: center;
}
                        `}
                    </code>
                </pre>

                <h2 className="text-2xl sm:text-3xl font-semibold text-blue-600 mb-6">Creating a Responsive Layout</h2>
                <p className="text-gray-700 mb-4">
                    One of the most powerful aspects of CSS Grid is its ability to create responsive designs without the need for media queries. By using flexible units like <code>fr</code> (fractional units), percentages, or the <code>repeat()</code> function, you can define grid structures that automatically adjust to the screen size.
                </p>

                <pre className=" p-4 rounded-lg mb-6 overflow-x-auto">
                    <code>
                        {`/* Responsive Grid Layout */
.container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
}
                        `}
                    </code>
                </pre>

                <h2 className="text-2xl sm:text-3xl font-semibold text-blue-600 mb-6">Advanced Techniques</h2>
                <p className="text-gray-700 mb-4">
                    CSS Grid also offers advanced features such as grid areas, subgrids, and the ability to overlap elements. Grid areas allow you to name specific sections of the grid and place grid items within those areas, simplifying the layout process for more complex designs.
                </p>

                <pre className=" p-4 rounded-lg mb-6 overflow-x-auto">
                    <code>
                        {`/* Grid Areas */
.container {
    display: grid;
    grid-template-areas:
        "header header"
        "sidebar content"
        "footer footer";
}

.header {
    grid-area: header;
}

.sidebar {
    grid-area: sidebar;
}

.content {
    grid-area: content;

.footer {
    grid-area: footer;
}
                        `}
                    </code>
                </pre>

                <h2 className="text-2xl sm:text-3xl font-semibold text-blue-600 mb-6">Conclusion</h2>
                <p className="text-gray-700 mb-4">
                    CSS Grid is a versatile and powerful tool that has simplified the process of creating responsive, grid-based layouts. Its intuitive syntax and robust feature set make it an essential skill for modern web developers. By mastering CSS Grid, you can streamline your workflow, create more efficient designs, and enhance the user experience across devices.
                </p>
            </div>
        </div>
    );
};

export default MagicOfCSSGrid;
