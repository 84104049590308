import React, { useContext } from 'react'
import './Home.css'
import { AppContext } from '../../contexts/AppContext'
import Landing from '../../components/landing/Landing'
import HowItWorks from '../../components/howItWorks/HowItWorks'
import FrequentlyAsked from '../../components/faq/FrequentlyAsked'
import { Slide } from 'react-awesome-reveal'
import Pricing from '../../components/pricing/Pricing'
import Mentoring from '../../components/Mentoring/Mentoring'
import { InfiniteTags } from '../../components/smallComponents/InfiniteTags/InfiniteTags'
import WhyTech from '../../components/whyTech/WhyTech'
import { FaBookOpen } from "react-icons/fa6";
import { RiComputerLine } from "react-icons/ri";
import Courses from '../../components/courses/Courses'
import { RiMoneyPoundCircleFill } from "react-icons/ri";
import { FaQuestionCircle } from "react-icons/fa";
import TechCompanies from "../../components/techCompanies/TechCompanies";
import Testimonials from "../../components/testimonials/Testimonials";
import ElevateSkills from '../../components/ElevateSkills/ElevateSkills'
import LearnfinityCourseGuide from '../../components/courses/LearnfinityCourseGuide'
import CareerPathway from '../../components/CareerPathways/CareerPathways'
import { general_faqs } from '../../data/FAQs'
import TestimonialVideo from '../../components/TestimonialVideo/TestimonialVideo'
import ContentOverview from '../../components/ContentOverview/ContentOverview'
import CareerPathways from '../../components/CareerPathways/CareerPathways'
import { Helmet } from 'react-helmet';

const Home = () => {
    const { } = useContext(AppContext)
    const techComapnyTitle = 'At Learnfinity, we are dedicated to equipping our students with the skills and knowledge they need to excel in the tech industry.';
    return (
        <div className='home-container'>
            <Helmet>
                <title>Learnfinity: Discover, Master, Excel in Tech Careers</title>
                <meta name="description" content="Join Learnfinity to advance your tech career with expert-led courses, personalized mentorship, and comprehensive career support. Start your journey to mastering tech skills today." />
                <meta property="og:title" content="Learnfinity: Discover, Master, Excel in Tech Careers" />
                <meta property="og:description" content="Join Learnfinity to advance your tech career with expert-led courses, personalized mentorship, and comprehensive career support. Start your journey to mastering tech skills today." />
                <meta property="og:url" content="https://www.learnfinity.co.uk/" />
                <meta property="og:image" content="https://www.learnfinity.co.uk/images/lf.png" />
                <meta property="twitter:title" content="Learnfinity: Discover, Master, Excel in Tech Careers" />
                <meta property="twitter:description" content="Join Learnfinity to advance your tech career with expert-led courses, personalized mentorship, and comprehensive career support. Start your journey to mastering tech skills today." />
                <meta property="twitter:image" content="https://www.learnfinity.co.uk/learnfinity-og-image.png" />
            </Helmet>
            <div className='container-1'>
                <Landing />
                <TechCompanies title={techComapnyTitle}/>
                <TestimonialVideo />
                <Mentoring mentoringTitle={'Learn, Master and Excel'}/>
                <LearnfinityCourseGuide />
                <ContentOverview />
                <div class="custom-shape-divider-bottom-1">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M1200,0H0V120H281.94C572.9,116.24,602.45,3.86,602.45,3.86h0S632,116.24,923,120h277Z" class="shape-fill"></path>
                    </svg>
                </div>
                <div class="custom-shape-divider-bottom-2">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M1200,0H0V120H281.94C572.9,116.24,602.45,3.86,602.45,3.86h0S632,116.24,923,120h277Z" class="shape-fill"></path>
                    </svg>
                </div>
                <div class="custom-shape-divider-bottom-3">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M1200,0H0V120H281.94C572.9,116.24,602.45,3.86,602.45,3.86h0S632,116.24,923,120h277Z" class="shape-fill"></path>
                    </svg>
                </div>
            </div>
            <div className='container-2'>

                {/* <FaBookOpen className='stencil-1' />
                <RiComputerLine className='stencil-2' /> */}
                {/* <Slide direction='right' duration={1500} triggerOnce>
                    <div>
                        <h1 style={{ textOrientation: 'upright' }}>Our Mentors</h1>
                       
                    </div>
                </Slide> */}
                <WhyTech />
                <InfiniteTags />
            </div>
            <div className='container-3'>

                <div className='c3-top-half' />

                <div class="custom-shape-divider-top-4">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M1200 0L0 0 598.97 114.72 1200 0z" class="shape-fill"></path>
                    </svg>
                </div>
                <RiMoneyPoundCircleFill className='stencil-3' />
                <FaQuestionCircle className='stencil-4' />
                <Slide direction='up' duration={1500} triggerOnce>
                    {/* <Pricing /> */}
                </Slide>
                <CareerPathways />
                <FrequentlyAsked color={'white'} faqs={general_faqs} />
                <ElevateSkills animationLineColor={'white'} title={'Your tech knowledge and career change is this close...'} />
            </div>

        </div>
    )
}

export default Home