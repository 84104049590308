export const foundationsModules = [
    {
        module: 1,
        title: "Foundation in IT and Programming",
        keyBenefit: "Build a strong foundation in essential IT skills and programming concepts, crucial for any tech role.",
        chapters: [
            { chapter: "1.1", title: "Introduction to Tools and Setup (Python, GitHub)", time: "0:30:00", benefit: "Get familiar with the essential tools used by developers worldwide." },
            { chapter: "1.2", title: "Basics of Computing: Hardware, Software, OS", time: "0:45:00", benefit: "Understand the core components of any computing system." },
            { chapter: "1.3", title: "Programming Foundations: Coding Concepts", time: "1:00:00", benefit: "Learn the fundamental coding concepts that are the building blocks of all software development." },
            { chapter: "1.4", title: "Version Control: Git and GitHub Basics", time: "0:40:00", benefit: "Master version control to manage and collaborate on code projects." },
            { chapter: "1.5", title: "Linux Basics: Introduction to CLI", time: "0:50:00", benefit: "Get comfortable with the command line interface, an essential skill for developers." },
            { chapter: "1.6", title: "Troubleshooting: Effective Research and Error Handling", time: "0:35:00", benefit: "Develop problem-solving skills essential for debugging and troubleshooting." }
        ],
        total: "4:20:00"
    },
    {
        module: 2,
        title: "Programming Essentials",
        keyBenefit: "Enhance your programming skills with more advanced concepts and practical coding exercises.",
        chapters: [
            { chapter: "2.1", title: "Python Basics: Data Types, Variables, Operators", time: "1:15:00", benefit: "Deepen your knowledge of Python, a versatile programming language." },
            { chapter: "2.2", title: "Control Structures: Conditionals and Loops", time: "1:00:00", benefit: "Learn how to control the flow of your programs using conditionals and loops." },
            { chapter: "2.3", title: "Mini-Projects: Daily Coding Challenges", time: "1:30:00", benefit: "Apply your skills to real-world problems and enhance your coding confidence." },
            { chapter: "2.4", title: "Mentor Checkpoint: Weekly Assessment and Q&A", time: "0:45:00", benefit: "Receive personalized feedback and support to ensure your understanding." }
        ],
        total: "4:30:00"
    },
    { 
        module: 3, 
        title: "Databases and Web Development Fundamentals", 
        keyBenefit: "Learn to build and manage databases and develop interactive web applications.",
        chapters: [
            { chapter: "3.1", title: "SQL Fundamentals: Basic Database Operations", time: "0:50:00", benefit: "Understand the basics of databases and how to manipulate data with SQL." },
            { chapter: "3.2", title: "SQL Project: Hands-on Practice", time: "1:10:00", benefit: "Apply your SQL skills in a practical project." },
            { chapter: "3.3", title: "Web Technologies: HTML, CSS, JavaScript", time: "1:20:00", benefit: "Learn the core technologies used in building websites." },
            { chapter: "3.4", title: "Full Stack Overview: Front-end and Back-end Basics", time: "1:15:00", benefit: "Gain a broad understanding of full-stack development." },
            { chapter: "3.5", title: "RESTful APIs: Basics and Creation", time: "1:00:00", benefit: "Learn how to build and use RESTful APIs to connect applications." },
            { chapter: "3.6", title: "Web Development Project: Build an Interactive Website", time: "1:30:00", benefit: "Consolidate your learning by building a fully functional website." },
            { chapter: "3.7", title: "Mentor Checkpoint: Review of Concepts and Projects", time: "0:45:00", benefit: "Get feedback on your progress and guidance for improvement." }
        ],
        total: "7:50:00"
    },
    { 
        module: 4, 
        title: "Advanced Software Engineering and Career Development", 
        keyBenefit: "Prepare for a successful career with advanced technical skills and career development training.",
        chapters: [
            { chapter: "4.1", title: "Cybersecurity Basics", time: "1:00:00", benefit: "Learn essential cybersecurity practices to protect applications." },
            { chapter: "4.2", title: "Cloud Computing: AWS, Azure, GCP Fundamentals", time: "1:15:00", benefit: "Understand the fundamentals of cloud services and how to deploy applications in the cloud." },
            { chapter: "4.3", title: "Artificial Intelligence: Introductory Concepts", time: "1:30:00", benefit: "Get an introduction to AI and its applications in modern software." },
            { chapter: "4.4", title: "Career Development: Resume Building", time: "0:45:00", benefit: "Create a professional resume that highlights your skills and experience." },
            { chapter: "4.5", title: "Career Development: Interview Preparation", time: "0:45:00", benefit: "Prepare for technical interviews with tips and practice questions." },
            { chapter: "4.6", title: "Career Development: LinkedIn Optimization", time: "0:30:00", benefit: "Enhance your LinkedIn profile to attract recruiters and networking opportunities." },
            { chapter: "4.7", title: "Project Management: Advanced Techniques and Strategies", time: "1:00:00", benefit: "Learn project management skills to effectively lead tech projects." },
            { chapter: "4.8", title: "Campus Day: Interactive Session with Industry Professionals", time: "1:00:00", benefit: "Engage with professionals and gain insights into the tech industry." },
            { chapter: "4.9", title: "Final Project Presentation and Graduation Ceremony", time: "1:30:00", benefit: "Showcase your final project and celebrate your achievements." },
            { chapter: "4.10", title: "Mentor Checkpoint: Final Review and Feedback", time: "0:45:00", benefit: "Receive final feedback to guide your next steps in the tech industry." }
        ],
        total: "10:00:00"
    }
];

export const foundationsHeroList = [
    'Learn with the #1 ranked 1-on-1 mentorship program',
    'Flexible learning schedule tailored for you',
    'Get a tech job in 3 months on average'
  ]
export const mentoringList = [
    'Receive personalized 1-on-1 mentorship from industry experts',
    'Gain hands-on experience with cutting-edge technologies',
    'Build and deploy full-stack web applications',
    'Enhance your skills with real-world projects in your portfolio',
  ]
