import React from 'react';
import './PaymentMethods.css';

const PaymentMethods = () => {
    const paymentData = [
        { src: 'https://upload.wikimedia.org/wikipedia/commons/4/41/Visa_Logo.png', alt: 'Visa' },
        { src:  require('../../assets/paymentMethods/mastercard.png'), alt: 'MasterCard' },
        { src: 'https://upload.wikimedia.org/wikipedia/commons/b/b5/PayPal.svg', alt: 'PayPal' },
        { src:  require('../../assets/paymentMethods/applepay.png'), alt: 'Apple Pay' },
        { src:  require('../../assets/paymentMethods/gpay.png'), alt: 'Google Pay' },
        { src:  require('../../assets/paymentMethods/alipay.png'), alt: 'Alipay' },
        { src:  require('../../assets/paymentMethods/klarna.png'), alt: 'Klarna' },
        { src:  require('../../assets/paymentMethods/americanexpress.png'), alt: 'American Express' }
    ];

    return (
        <div className='payment-methods-container'>
            <h4>Payment Methods secured by <span>Stripe</span></h4>
            <div className='payment-methods-list'>
                <div className='inner'>
                    {[...paymentData, ...paymentData, ...paymentData].map((payment, index) => (
                        <div key={index} className='payment-method-card'>
                            <img src={payment.src} alt={payment.alt} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default PaymentMethods;
