import React from 'react';
import "./Footer.css";
import { NavLink, Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
const Footer = () => {
  return (
    <footer className="bg-gray-900">
      <div className="max-w-screen-xl px-4 pt-16 pb-6 mx-auto sm:px-6 lg:px-8 lg:pt-24">
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
          <div>
            <div className="flex justify-left text-teal-300 sm:justify-start">
            <img src={require('../../assets/logos/learnfinity-footer-logo.png')} alt="Learnfinity Logo" className="footer-logo" />
            </div>

            <p className="max-w-md mx-auto mt-6 leading-relaxed text-left text-gray-400 sm:max-w-xs sm:mx-0 sm:text-left">
            Gain hands-on experience and master digital platform development and design with guidance from industry experts.
            </p>

            <ul className="flex justify-left gap-6 mt-8 md:gap-8 sm:justify-start">
              <li>
                <a
                  href="https://www.facebook.com/people/Learnfinity/100093060918942/"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="text-teal-500 transition hover:text-teal-500/75"
                >
                  <span className="sr-only">Facebook</span>
                  <img src={require('../../assets/techCompanies/facebook.png')} alt="Facebook" className='social'/>
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/learnfinityltd/"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="text-teal-500 transition hover:text-teal-500/75"
                >
                  <span className="sr-only">Instagram</span>
                  <img src={require('../../assets/techCompanies/instagram.png')} alt="Instagram" className='social'/>
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/learnfinityltd"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="text-teal-500 transition hover:text-teal-500/75"
                >
                  <span className="sr-only">X</span>
                  <img src={require('../../assets/techCompanies/x.png')} alt="X" className='social'/>
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/learnfinity-ltd"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="text-teal-500 transition hover:text-teal-500/75"
                >
                  <span className="sr-only">LinkedIn</span>
                  <img src={require('../../assets/techCompanies/linkedin.png')} alt="LinkedIn" className='social'/>
                </a>
              </li>
            </ul>
          </div>

          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:col-span-2 md:grid-cols-4">
            <div className="text-left sm:text-left">
              <p className="text-lg font-medium text-white">About Us</p>

              <nav className="mt-8">
                <ul className="space-y-4 text-sm">
                  <li>
                    <a
                      className="text-white transition hover:text-white/75"
                      href="/"
                    >
                      <HashLink to="/why-us">Why Us</HashLink>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>

            <div className="text-left sm:text-left">
              <p className="text-lg font-medium text-white">Our Courses</p>

              <nav className="mt-8">
                <ul className="space-y-4 text-sm">
                  <li>
                    <a
                      className="text-white transition hover:text-white/75"
                      href="/"
                    >
                      <HashLink to="/foundations">Foundations Of IT And CyberSecurity</HashLink>
                    </a>
                  </li>

                  <li>
                    <a
                      className="text-white transition hover:text-white/75"
                      href="/"
                    >
                      <HashLink to="/full-stack">Full Stack Web Development</HashLink>
                    </a>
                  </li>

                  <li>
                    <a
                      className="text-white transition hover:text-white/75"
                      href="/"
                    >
                     <HashLink to="/advanced">Advanced Software Engineering And AI</HashLink>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>

            <div className="text-left sm:text-left">
              <p className="text-lg font-medium text-white">Helpful Links</p>

              <nav className="mt-8">
                <ul className="space-y-4 text-sm">
                  <li>
                    <a
                      className="text-white transition hover:text-white/75"
                      href="/"
                    >
                      <HashLink to="/#FAQ">FAQs</HashLink>
                    </a>
                  </li>

                  <li>
                    <a
                      className="text-white transition hover:text-white/75"
                      href="mailto:support@learnfinity.co.uk"
                    >
                      Support
                    </a>
                  </li>

                  {/* <li>
                    <a
                      className="flex group justify-left sm:justify-start gap-1.5"
                      href="/"
                    >
                      <span className="text-white transition group-hover:text-white/75">
                        Live Chat
                      </span>

                      <span className="relative flex w-2 h-2 -mr-2">
                        <span className="absolute inline-flex w-full h-full bg-teal-400 rounded-full opacity-75 animate-ping"></span>
                        <span className="relative inline-flex w-2 h-2 bg-blue-500 rounded-full"></span>
                      </span>
                    </a>
                  </li> */}
                </ul>
              </nav>
            </div>

            <div className="text-left sm:text-left">
              <p className="text-lg font-medium text-white">Contact Us</p>

              <ul className="mt-8 space-y-4 text-sm">
                <li>
                  <a
                    className="flex items-center justify-left sm:justify-start gap-1.5 group"
                    href="/"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-5 h-5 text-white shrink-0"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                      />
                    </svg>

                    <span className="text-white transition group-hover:text-white/75">
                      support@learnfinity.co.uk
                    </span>
                  </a>
                </li>

                <li>
                  <a
                    className="flex items-center justify-left sm:justify-start gap-1.5 group"
                    href="/"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-5 h-5 text-white shrink-0"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                      />
                    </svg>

                    <span className="text-white transition group-hover:text-white/75">
                    0203 576 4076
                    </span>
                  </a>
                </li>

                {/* <li className="flex items-start justify-left gap-1.5 sm:justify-start">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5 text-white shrink-0"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                  </svg>

                  <address className="-mt-0.5 not-italic text-white">
                    43 Priors Croft, London, United Kingdom
                  </address>
                </li> */}
              </ul>
            </div>
          </div>
        </div>

        <div className="pt-6 mt-12 border-t border-gray-900">
          <div className="text-left sm:flex sm:justify-between sm:text-left">
            <p className="text-sm text-gray-400">
              <a
                className="inline-block text-teal-700 underline transition hover:text-teal-500/75 highlight"
                href="/"
              >
                <NavLink to="/terms-conditions">Terms & Conditions</NavLink>
              </a>

              <span>&middot;</span>

              <a
                className="inline-block text-teal-700 underline transition hover:text-teal-500/75 highlight"
                href="/"
              >
                <NavLink to="/cookie-policy">Cookie Policy</NavLink>
              </a>
              <span>&middot;</span>

              <a
                className="inline-block text-teal-700 underline transition hover:text-teal-500/75 highlight"
                href="/"
              >
                <NavLink to="/privacy_policy">Privacy Policy</NavLink>
              </a>
              <span>&middot;</span>

              <a
                className="inline-block text-teal-700 underline transition hover:text-teal-500/75 highlight"
                href="/"
              >
                <NavLink to="/disclaimer">Disclaimer</NavLink>
              </a>
            </p>

            <p className="mt-4 text-sm text-gray-500 sm:order-first sm:mt-0">
                Learnfinity LTD ('Learnfinity') is a registered company in England and Wales, registration number 14977930
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
