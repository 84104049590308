export const advancedModules = [
    {
        module: 1,
        title: "Advanced Programming Techniques",
        keyBenefit: "Master advanced programming skills, enabling you to solve complex problems efficiently and design robust applications.",
        chapters: [
            { chapter: "1.1", title: "Advanced Python and JavaScript Concepts", time: "1:30:00" },
            { chapter: "1.2", title: "Asynchronous Programming", time: "1:15:00" },
            { chapter: "1.3", title: "Advanced Data Structures and Algorithms", time: "1:45:00" },
            { chapter: "1.4", title: "Software Design Patterns", time: "1:30:00" },
            { chapter: "1.5", title: "Code Optimization and Performance Tuning", time: "1:15:00" },
            { chapter: "1.6", title: "Secure Coding Practices", time: "1:00:00" },
            { chapter: "1.7", title: "Parallel and Distributed Computing", time: "1:30:00" }
        ],
        total: "9:45:00"
    },
    {
        module: 2,
        title: "Emerging Technologies",
        keyBenefit: "Gain in-depth knowledge of cutting-edge technologies and stay ahead of the curve in the tech industry.",
        chapters: [
            { chapter: "2.1", title: "Introduction to Blockchain Technology", time: "1:15:00" },
            { chapter: "2.2", title: "Smart Contracts Development", time: "1:30:00" },
            { chapter: "2.3", title: "Quantum Computing Fundamentals", time: "1:45:00" },
            { chapter: "2.4", title: "Augmented and Virtual Reality Development", time: "1:30:00" },
            { chapter: "2.5", title: "Exploring the Internet of Things (IoT)", time: "1:15:00" },
            { chapter: "2.6", title: "Edge Computing and its Applications", time: "1:15:00" },
            { chapter: "2.7", title: "5G Technology and its Impact", time: "1:00:00" }
        ],
        total: "9:30:00"
    },
    {
        module: 3,
        title: "Data Science and Machine Learning",
        keyBenefit: "Become proficient in data science and machine learning techniques, equipping you to handle real-world data challenges.",
        chapters: [
            { chapter: "3.1", title: "Advanced Data Analytics Techniques", time: "1:30:00" },
            { chapter: "3.2", title: "Deep Learning and Neural Networks", time: "2:00:00" },
            { chapter: "3.3", title: "Natural Language Processing (NLP)", time: "1:45:00" },
            { chapter: "3.4", title: "AI Ethics and Fairness", time: "1:30:00" },
            { chapter: "3.5", title: "Machine Learning Deployment and Monitoring", time: "1:30:00" },
            { chapter: "3.6", title: "Capstone Project: Real-World Data Science Problem", time: "2:00:00" },
            { chapter: "3.7", title: "Reinforcement Learning", time: "1:30:00" }
        ],
        total: "11:45:00"
    },
    {
        module: 4,
        title: "Cloud and DevOps Mastery",
        keyBenefit: "Learn how to build, deploy, and manage scalable applications in the cloud using industry-standard DevOps practices.",
        chapters: [
            { chapter: "4.1", title: "Advanced Cloud Computing with AWS, Azure, and GCP", time: "2:00:00" },
            { chapter: "4.2", title: "Kubernetes and Container Orchestration", time: "1:45:00" },
            { chapter: "4.3", title: "Infrastructure as Code (IaC) with Terraform", time: "1:30:00" },
            { chapter: "4.4", title: "Continuous Integration and Continuous Deployment (CI/CD)", time: "1:30:00" },
            { chapter: "4.5", title: "DevOps Best Practices and Culture", time: "1:15:00" },
            { chapter: "4.6", title: "Cloud Security and Compliance", time: "1:15:00" },
            { chapter: "4.7", title: "Serverless Architectures", time: "1:00:00" }
        ],
        total: "10:15:00"
    },
    {
        module: 5,
        title: "Cybersecurity and Ethical Hacking",
        keyBenefit: "Master advanced cybersecurity skills and learn ethical hacking techniques to protect systems and data.",
        chapters: [
            { chapter: "5.1", title: "Advanced Network Security", time: "1:30:00" },
            { chapter: "5.2", title: "Penetration Testing and Vulnerability Assessment", time: "1:45:00" },
            { chapter: "5.3", title: "Cryptography and Data Protection", time: "1:30:00" },
            { chapter: "5.4", title: "Incident Response and Forensics", time: "1:30:00" },
            { chapter: "5.5", title: "Ethical Hacking Tools and Techniques", time: "1:45:00" },
            { chapter: "5.6", title: "Cybersecurity Compliance and Regulations", time: "1:30:00" },
            { chapter: "5.7", title: "Security in IoT Devices", time: "1:00:00" }
        ],
        total: "10:30:00"
    },
    {
        module: 6,
        title: "Leadership and Innovation in Tech",
        keyBenefit: "Develop leadership skills and innovative strategies to drive success in technology-driven environments.",
        chapters: [
            { chapter: "6.1", title: "Innovation Strategies and Mindset", time: "1:15:00" },
            { chapter: "6.2", title: "Building and Leading High-Performing Tech Teams", time: "1:30:00" },
            { chapter: "6.3", title: "Project Management for Tech Leaders", time: "1:30:00" },
            { chapter: "6.4", title: "Strategic Decision Making and Problem Solving", time: "1:15:00" },
            { chapter: "6.5", title: "Networking with Industry Leaders", time: "1:00:00" },
            { chapter: "6.6", title: "Exclusive Mentoring Sessions with Tech Innovators", time: "1:30:00" },
            { chapter: "6.7", title: "Entrepreneurship in Tech", time: "1:15:00" }
        ],
        total: "9:15:00"
    },
    {
        module: 7,
        title: "Final Capstone Project",
        keyBenefit: "Apply your knowledge and skills in a comprehensive capstone project, showcasing your expertise to potential employers.",
        chapters: [
            { chapter: "7.1", title: "Project Proposal and Planning", time: "1:30:00" },
            { chapter: "7.2", title: "Development and Implementation", time: "2:30:00" },
            { chapter: "7.3", title: "Testing, Debugging, and Optimization", time: "2:00:00" },
            { chapter: "7.4", title: "Final Presentation and Demonstration", time: "1:30:00" },
            { chapter: "7.5", title: "Peer Review and Feedback", time: "1:00:00" },
            { chapter: "7.6", title: "Graduation and Certification Ceremony", time: "1:00:00" }
        ],
        total: "9:30:00"
    }
];


export const advancedHeroList = [
    'Master the latest tools in AI, Machine Learning, and Cybersecurity',
    'Lead innovation with strategic insights and exclusive mentorship',
    'Develop high-impact projects that demonstrate elite technical skills',
    'Prepare for leadership roles in the most cutting-edge tech fields'
];

