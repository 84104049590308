import React, { useEffect } from 'react';
import './Testimonials.css';
import { testimonials } from '../../data/testimonials';

const Testimonials = ({titleColorChange}) => {
    const titleColor = titleColorChange ? '#444141' : null;

    useEffect(() => {
        const track = document.querySelector('.carousel-track');
        const clonedNodes = track.innerHTML;
        track.innerHTML += clonedNodes;
    }, []);

    return (
        <section className="testimonials-container">
            <h1 className="section-title" style={{color: titleColor}}>What Our <span className={titleColor ? `highlight` : null}>Students</span> Had To Say...</h1>
            <div className="carousel">
                <div className="carousel-track">
                    {testimonials.map((testimonial, index) => (
                        <div key={index} className="testimonial-card">
                            <div className="testimonial-text">
                                {testimonial.image ? <img src={testimonial.image} alt={`${testimonial.name}'s avatar`} className="testimonial-image"/> : `${testimonial.text}`}
                            </div>
                            <div className="testimonial-author">
                                <img src={testimonial.avatar} alt={`${testimonial.name}'s avatar`} className="testimonial-avatar" />
                                <img src={testimonial.flag} alt="flag" className="testimonial-flag" />
                                <div className="author-details">
                                    <div className="author-name">{testimonial.name}</div>
                                    <div className="author-handle">{testimonial.handle}</div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Testimonials;
