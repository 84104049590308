import React from 'react';
import './ToolsIcons.css';
import pythonIcon from '../../assets/smallAssets/python.png';
import reactIcon from '../../assets/smallAssets/react.png';
import jsIcon from '../../assets/smallAssets/javascript.png';
import github from '../../assets/smallAssets/github.png';
import gitlab from '../../assets/smallAssets/gitlab.png';
import html from '../../assets/smallAssets/html.png';
import cSharp from '../../assets/smallAssets/c-sharp.png';
import java from '../../assets/smallAssets/java.png';
import css from '../../assets/smallAssets/css.png';

const ToolsIcons = () => {
  const tools = [
    { src: html, name: "HTML" },
    { src: css, name: "CSS" },
    { src: jsIcon, name: "JavaScript" },
    { src: reactIcon, name: "React" },
    { src: pythonIcon, name: "Python" },
    { src: cSharp, name: "C#" },
    { src: java, name: "Java" },
    { src: github, name: "GitHub" },
    { src: gitlab, name: "GitLab" },
  ];

  return (
    <div className="icon-row-container">
      {tools.map((tool, index) => (
        <div className="icon-item" key={index}>
          <img src={tool.src} alt={tool.name} />
          <span className='highlight'>{tool.name}</span>
        </div>
      ))}
    </div>
  );
};

export default ToolsIcons;
