import React from 'react';
import './EndlessLearningTools.css';
import learnfinityLogo from '../../assets/logos/learnfinity-logo.png';
import pythonIcon from '../../assets/smallAssets/python.png';
import reactIcon from '../../assets/smallAssets/react.png';
import jsIcon from '../../assets/smallAssets/javascript.png';
import github from '../../assets/smallAssets/github.png';
import gitlab from '../../assets/smallAssets/gitlab.png';
import html from '../../assets/smallAssets/html.png';
import cSharp from '../../assets/smallAssets/c-sharp.png';
import java from '../../assets/smallAssets/java.png';
import css from '../../assets/smallAssets/css.png';

const EndlessLearningTools = () => {
  const tools = [
    { src: learnfinityLogo, name: "Learnfinity" },
    { src: html, name: "HTML" },
    { src: css, name: "CSS" },
    { src: jsIcon, name: "JavaScript" },
    { src: reactIcon, name: "React" },
    { src: pythonIcon, name: "Python" },
    { src: cSharp, name: "C#" },
    { src: java, name: "Java" },
    { src: github, name: "GitHub" },
    { src: gitlab, name: "GitLab" },
  ];

  return (
    <div className="endless-learning-container">
      <h2 className='highlight-title highlight'>Learnfinity's Infinite Learning Approach</h2>
      <p>Learnfinity's approach will provide you with a continuous, infinite learning experience designed to keep you at the forefront of the tech industry.</p>
      <div className="endless-icon-row">
        {tools.map((tool, index) => (
          <div className={`tool-item ${index === 0 ? 'learnfinity-icon' : ''}`} key={index}>
            <img src={tool.src} alt={tool.name} />
          </div>
        ))}
        <div className="ellipsis-text highlight-title highlight">...</div>
      </div>
    </div>
  );
};

export default EndlessLearningTools;
