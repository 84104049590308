import React from 'react';
import '../CourseDetails.css';
import PaymentMethods from '../../PaymentMethods/PaymentMethod';
import  {advancedHeroList, advancedModules} from '../../../data/advancedModules'
import Testimonials from '../../testimonials/Testimonials';
import FrequentlyAsked from '../../faq/FrequentlyAsked';
import { Slide } from 'react-awesome-reveal';
import ModuleBreakdown from '../../ModuleBreakdown/ModuleBreakdown';
import HeroSection from '../../HeroSection/HeroSection';
import { HashLink } from 'react-router-hash-link';
import CourseAudience from '../../CourseAudience/CourseAudience';
import { useLocation  } from 'react-router-dom';
import DSMLQuiz from '../../DSMLQuiz/DSMLQuiz';
import HandsOnLab from '../../HandsOnLab/HandsOnLab';
import { eliteHeroList } from '../../../data/advancedModules';
import rocket from "../../../assets/smallAssets/rocket.png";
import Pricing from '../../pricing/Pricing';
import { eliteCourseFAQ } from '../../../data/FAQs';
import dataScience from "../../../assets/materials/data-science.png";
import AdvancedHero from '../../HeroSection/AdvancedHero/AdvancedHero';
import { Helmet } from 'react-helmet';

const Advanced = () => {
  const location = useLocation();
  const course = location.pathname.replace('/', '');
  const title = 'Master-Level Training for Future Tech Leaders';
  const cleanPathname = location.pathname.replace(/\/+$/, '');
  const description = 'Join our advanced course to gain cutting-edge skills and become a leader in the tech industry.';
  const metaTitle = 'Learnfinity Advanced: Master-Level Training for Future Tech Leaders';
  const metaDescription = `Elevate your tech career with Learnfinity's Advanced course. Personalized mentorship and hands-on training to guide you to leadership roles in the tech industry.`
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={metaDescription} />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:image" content="https://www.learnfinity.co.uk/images/images/lf.png" />
        <meta property="og:url" content="https://www.learnfinity.co.uk/advanced" />
        <meta property="og:type" content="website" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:title" content={metaTitle} />
        <meta property="twitter:description" content={metaDescription} />
        <meta property="twitter:image" content="https://www.learnfinity.co.uk/path/to/image.jpg" />
      </Helmet>
      <AdvancedHero course={course} title={title} advancedHeroList={advancedHeroList}  />
      <div id="module-1">
        <ModuleBreakdown courseName={'eliteModules'} course={advancedModules} />
      </div>
      <div id="start-learning">
        <CourseAudience courseAudience={'eliteModules'} />
      </div>
      <div className="course-buttons">          
        <HashLink to={`${cleanPathname}#pricing`} smooth>
          <button className="course-button">
            Start Learning Now
            <img width="30px" className="rocket" src={rocket} alt="rocket"/>
          </button>
        </HashLink>
      </div>
      <PaymentMethods />
      <div id="pricing">
        <Pricing courseName={'advanced'} images={dataScience} />
      </div>
      <FrequentlyAsked color={'#0e346d'} faqs={eliteCourseFAQ} id="advanced-faq"/>
    </>
  );
};

export default Advanced;
