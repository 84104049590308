import React, { useState } from 'react';
import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";
import { FaChalkboardTeacher } from 'react-icons/fa';

const ModuleBreakdown = ({ courseName, course }) => {
    const [activeIndex, setActiveIndex] = useState(null);

    const handleToggle = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <div className="max-w-5xl mx-auto px-4 py-12">
            <h3 className="text-4xl font-extrabold text-gray-900 text-center mb-12 highlight">
                Your Personalized 1-1 Mentorship Structure
            </h3>
            <div className="space-y-6">
                {course.map((module, index) => (
                    <div
                        key={module.module}
                        className="bg-gradient-to-r from-blue-50 via-white to-blue-50 rounded-lg shadow-md overflow-hidden"
                    >
                        <div
                            className="flex justify-between items-center bg-blue-600 text-white px-6 py-4 cursor-pointer"
                            onClick={() => handleToggle(index)}
                        >
                            <div className="flex items-center space-x-4">
                                <div className="w-8 flex justify-center">
                                    {activeIndex === index ? (
                                        <IoIosArrowDropup className="text-2xl md:text-3xl" />
                                    ) : (
                                        <IoIosArrowDropdown className="text-2xl md:text-3xl" />
                                    )}
                                </div>
                                <div className="flex flex-col">
                                    <span className="text-lg md:text-xl font-semibold tracking-wide">{module.title}</span>
                                </div>
                            </div>
                            <div className="ml-auto flex flex-col md:flex-row md:space-x-2 items-center md:items-start text-center md:text-left">
                                <span className="text-sm md:text-base font-light">Mentoring Time</span>
                                <span className="text-sm md:text-base font-light">{module.total}</span>
                            </div>
                        </div>
                        {activeIndex === index && (
                            <div className="bg-white px-6 py-4">
                                <h4 className="text-2xl font-semibold text-blue-600 mb-4">Key Benefits:</h4>
                                <p className="text-gray-800 mb-6 leading-relaxed">{module.keyBenefit}</p>
                                <div className="divide-y divide-gray-200">
                                    {module.chapters.map((chapter, idx) => (
                                        <div key={idx} className="flex justify-between items-center py-3">
                                            <span className="text-lg font-medium text-gray-900">
                                                {chapter.chapter}: {chapter.title}
                                            </span>
                                            <div className="flex items-center text-gray-600">
                                                <FaChalkboardTeacher className="mr-2 text-xl text-blue-600" />
                                                <span className="text-sm md:text-base">{chapter.time}</span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ModuleBreakdown;
