import React, { useState } from 'react';
import './Pricing.css';
import { FaGraduationCap } from 'react-icons/fa';
import { priceTagSvg } from '../../assets/svg/svg';
import { techCourses, pricingPlans } from '../../data/CoursesData';
import homeWork from "../../assets/materials/hw-exercises.png";
import moneyBack from "../../assets/stockImages/money-back.png";
import learnfinity from "../../assets/logos/learnfinity-logo.png";
import { testimonials } from '../../data/testimonials';

const Pricing = ({ courseName, images }) => {
    const [paymentPlan, setPaymentPlan] = useState(3); // Default to 3 months

    const handleBuyNowClick = (oneTimePayment) => {
        const paymentLinkRedirect = oneTimePayment ? course.paymentLink : paymentPlan === 3 ? course.threeMonthPaymentLink : course.sixMonthPaymentLink;
        window.location.href = paymentLinkRedirect;
    };

    const calculateMonthlyPlan = (totalPrice, installments) => { 
        const monthlyPayment = Math.ceil(totalPrice / installments); 
        return `£${monthlyPayment} per month`;
    };
    const course = techCourses.find(course => course.level.toLowerCase() === courseName);
    if (!course) {
        return <div>Course not found</div>;
    }

    const totalPrice = parseInt(course.price.replace('£', '').replace(',', ''));
    const monthlyPlan = calculateMonthlyPlan(totalPrice, paymentPlan);

    return (
        <div className="pricing-container">
            <h1 className="pricing-header">Get the course and become a <span className="highlight">Learnfinity Member</span></h1>
            <p className="pricing-subheader">
                Choose the payment option that suits you best and gain <span className="highlight">lifetime access</span> to all course materials, mentoring, and support.
            </p>
            <div className="pricing-cards">
                <div className="pricing-card small-card">
                    <div className="image-placeholder">
                        <img src={learnfinity} alt="hardWorking" />
                    </div>
                    <div className="pricing-card-content">
                        <h2 className='highlight'>{course.title}</h2>
                        <div className="price">{course.price} one-time payment</div>
                        <hr className="divider"/>
                        
                        <button className="btn cta-button enroll-now" id="alternate-button" onClick={() => handleBuyNowClick(true)}>Buy Now</button>
                        <ul className="feature-list">
                            {pricingPlans[0].features.map((feature, index) => (
                                <li key={index}>
                                    <img style={{ paddingRight: '0.2rem', marginBottom: '-0.2rem' }} src={feature.image} alt={feature.image} />
                                    {feature.feature}
                                </li>
                            ))}
                        </ul>
                        {/* <p className='testimonial'>"{testimonials[3].text}" - {testimonials[3].name}</p>
                        <p className='testimonial'>"{testimonials[1].text}" - {testimonials[1].name}</p> */}
                    </div>
                </div>

                <div className="pricing-card large-card">
                    <div className="image-placeholder">
                        <img src={images ? images : homeWork} alt={images ? images : homeWork} />
                    </div>
                    <div className="pricing-card-content">
                        <h2 className='flexible highlight'>Flexible Payment Plans</h2>
                        <hr className="divider"/>
                        <div className="payment-switches">
                            <button
                                className={`payment-switch ${paymentPlan === 3 ? 'active' : ''}`}
                                onClick={() => setPaymentPlan(3)}
                            >
                                3 Months
                            </button>
                            <button
                                className={`payment-switch ${paymentPlan === 6 ? 'active' : ''}`}
                                onClick={() => setPaymentPlan(6)}
                            >
                                6 Months
                            </button>
                        </div>
                        <div className="price">{monthlyPlan}</div>
                        <button className="btn cta-button enroll-now" id="alternate-button" onClick={() => handleBuyNowClick(false)}>Enroll Now</button>
                        <div className="bonus-tag">Bonus</div>
                        <p className='testimonial'>"{testimonials[1+3].text}" - {testimonials[1+3].name}</p>
                    </div>
                </div>
            </div>
            <img className='money-back' width="30%" src={moneyBack} alt={moneyBack} />
        </div>
    );
};

export default Pricing;
