import React, { useState } from 'react';
import './FrequentlyAsked.css';

const FrequentlyAsked = ({color, faqs}) => {
    const [activeIndex, setActiveIndex] = useState(null); // to track the currently active question

    const handleToggle = (index) => {
        setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    return (
        <>
            <div itemscope itemtype="https://schema.org/FAQPage" id='FAQ'>
                <div className="faq-container">
                    <h1 className="faq-title" style={{color: color}}>Frequently Asked Questions</h1>
                    <ul className="faq-list">
                        {faqs.map((faq, index) => (
                            <li key={index} className="faq-item" itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
                                <div
                                    className={`faq-label ${activeIndex === index ? 'active' : ''}`}
                                    onClick={() => handleToggle(index)}
                                    itemprop="name"
                                >
                                    {faq.question}
                                    <svg
                                        className={`faq-icon ${activeIndex === index ? 'rotate' : ''}`}
                                        width="22"
                                        height="23"
                                        viewBox="0 0 22 23"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M1 11.5L21 11.5"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                        />
                                        <path
                                            d="M11 1.5L11 21.5"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                        />
                                    </svg>
                                </div>
                                <div
                                    className={`faq-answer-container ${activeIndex === index ? 'expanded' : ''}`}
                                    itemscope
                                    itemprop="acceptedAnswer"
                                    itemtype="https://schema.org/Answer"
                                >
                                    <div className="faq-answer" itemprop="text">
                                        {faq.answer}
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </>
    );
};

export default FrequentlyAsked;
