export const intro_to_software_data = [
    {
        title: "Week 1 : Intro into IT",
        topics: [
            "Computer Basics",
            "Foundations of programming",
            "Version control",
            "Linux basics",
            "Debugging bug",
        ]
    },
    {
        title: "Week 2 : Fundamentals of Programming",
        topics: [
            "Intro to Python 🐍",
            "Control Structures",
            "Mini-projects",
            "Deep dive in Python",
            "Daily coding challenges",
        ]
    },
    {
        title: "Week 3 - 4 : Fundamentals of Database Management",
        topics: [
            "Intro to SQL",
            "Mini-projects",
            "DBMS",
            "Relational and Non-Relational Databases",
            "Firebase, Postgress, MongoDB",
        ]
    },
    {
        title: "Week 4 : Fundamentals of Web-Development",
        topics: [
            "Intro to HTML",
            "Intro to CSS",
            "Intro to JavaScript",
            "Full-Stack overview",
            "APIs",
            "Website mini-project",
        ]
    },
    {
        title: "Week 4 - 5 : Careers & Next Steps",
        topics: [
            "Intro to Cybersecurity 🔐",
            "Intro to Cloud Computing",
            "Intro to AI",
            "CV workshop",
            "Interview Preparation",
            "LinkedIn optimization",
            "Campus Day 🏦",
            "Final Project Presentation",
            "Graduation 🎓"
        ]
    }
]



export const prices_data = [
    {
        type: '3 Month Installments',
        price: 400,
        plan: 'Intro to Software Engineering',
        details: [
            {
                text: ' No prior experience required',
                available: true
            },
            {
                text: 'Flexible scheduling',
                available: true
            },
            {
                text: 'Additional Materials (FREE)',
                available: true
            },

            {
                text: 'Certification & Recognition',
                available: true
            },
            {
                text: 'Alumni Support',
                available: false
            },
        ]
    },
    {
        type: 'Full Payment',
        price: 1149,
        plan: 'Intro to Software Engineering',
        details: [
            {
                text: ' No prior experience required',
                available: true
            },
            {
                text: 'Flexible scheduling',
                available: true
            },
            {
                text: 'Additional Materials (FREE)',
                available: true
            },

            {
                text: 'Certification & Recognition',
                available: true
            },
            {
                text: 'Alumni Support',
                available: true
            },
            // {
            //     text: 'Guaranteed One to One with Mentor',
            //     available: true
            // },
            // {
            //     text: 'Save £51 off course',
            //     available: true
            // },

        ]
    },
    {
        type: 'More Coming Soon',
        price: '',
        plan: '',
        details: [

        ]
    }
]
export const techCourses = [
    {
        title: "Foundations of IT and Cybersecurity",
        level: "Foundations",
        instructor: "Anwar Abdi",
        category: "Beginner Course / 5 weeks",
        price: "£1,200",
        oldPrice: "£800 (Early Bird)",
        paymentLink: 'https://buy.stripe.com/bIY7vK8Gp82leeQaEO',
        threeMonthPaymentLink: 'https://buy.stripe.com/00gg2g09T0zTgmY9AN',
        sixMonthPaymentLink: 'https://buy.stripe.com/fZe7vK5ud0zT9YAfZa',
        newTag: true,
        buttonText: "Enroll Now",
        previewText: "Free Preview",
        learnMoreText: "Learn More",
        icon: 'FaLaptopCode',
        imgSrc: require('../assets/courseImages/Cover1.png'),
        description: "Ideal for those looking for an introduction to technical concepts in IT and basic cybersecurity practices. Covers skills required for entry-level roles in various IT sectors.",
        features: [
            { image: require('../assets/smallAssets/whytech/books.png'), feature: 'Comprehensive introduction to IT concepts' },
            { image: require('../assets/smallAssets/spanner.png'), feature: 'Skills required for entry-level roles' },
            { image: require('../assets/smallAssets/whytech/hardworking.png'), feature: 'Hands-on experience with key tools and technologies' },
            { image: require('../assets/smallAssets/padlock.png'), feature: 'Basics of cybersecurity practices' },
            { image: require('../assets/smallAssets/launchpad.png'), feature: 'Foundational knowledge for further tech education' },
            { image: require('../assets/smallAssets/mentor.png'), feature: 'Free trial and mentoring support included' }
        ]
    },
    {
        title: "Full-Stack Web Development Mastery",
        level: "Full-Stack",
        instructor: "Faisal Bhuyian",
        category: "Web Development / 12 weeks",
        price: "£2,499",
        oldPrice: "£1,000 (Early Bird)",
        paymentLink: 'https://buy.stripe.com/cN203ibSB5Ud8Uw7sB',
        threeMonthPaymentLink: 'https://buy.stripe.com/5kA4jy5ud4Q9c6I14j',
        sixMonthPaymentLink: 'https://buy.stripe.com/28ocQ4cWFfuN6MocN2',
        newTag: true,
        buttonText: "Enroll Now",
        previewText: "Free Preview",
        icon: 'FaCode',
        imgSrc: require('../assets/courseImages/Cover2.png'),
        mostPopular: true,
        description: "Learn the full stack of web development technologies, from front-end HTML/CSS to back-end databases and RESTful APIs. Get hands-on with real-world projects under expert mentorship.",
        features: [
            { image: require('../assets/smallAssets/html.png'), feature: 'Advanced HTML & CSS techniques' },
            { image: require('../assets/smallAssets/javascript.png'), feature: 'JavaScript ES6+ features' },
            { image: require('../assets/smallAssets/react.png'), feature: 'React.js deep dive' },
            { image: require('../assets/smallAssets/spanner.png'), feature: 'Backend development with Node.js and Express' },
            { image: require('../assets/smallAssets/bar-chart.png'), feature: 'Database integration with MongoDB' },
            { image: require('../assets/smallAssets/www.png'), feature: 'Building RESTful APIs' },
            { image: require('../assets/smallAssets/mentor.png'), feature: 'One-on-one mentoring to guide you through complex projects' }
        ]
    },
    {
        title: "Advanced Software Engineering and AI",
        level: "Advanced",
        instructor: "Anwar Abdi",
        category: "Software Engineering / 6 months",
        price: "£4,999",
        oldPrice: "£1,500 (Early Bird)",
        paymentLink: 'https://buy.stripe.com/aEUeYccWF6Yh5IkcMX',
        threeMonthPaymentLink: 'https://buy.stripe.com/cN2cQ4f4N0zT1s4aEV',
        sixMonthPaymentLink: 'https://buy.stripe.com/dR63fue0J5Ud8Uw6oG',
        buttonText: "Enroll Now",
        previewText: "Free Preview",
        icon: 'FaCogs',
        imgSrc: require('../assets/courseImages/Cover3.png'),
        description: "Master advanced software engineering techniques and explore the world of artificial intelligence, cloud computing, and cybersecurity. The program includes project management and a final capstone project.",
        features: [
            { image: require('../assets/smallAssets/padlock.png'), feature: 'Advanced cybersecurity techniques' },
            { image: require('../assets/smallAssets/cloud-storage.png'), feature: 'Cloud computing with AWS and Azure' },
            { image: require('../assets/smallAssets/robot.png'), feature: 'Artificial Intelligence and Machine Learning' },
            { image: require('../assets/smallAssets/bar-chart.png'), feature: 'Project management and final project presentation' },
            { image: require('../assets/smallAssets/mentor.png'), feature: 'Access to exclusive mentoring sessions' },
            { image: require('../assets/smallAssets/whytech/suitcase.png'), feature: 'Comprehensive career development support' }
        ]
    },
    // New courses
    {
        title: "Blockchain and Cryptocurrency Development",
        level: "Advanced",
        instructor: "Sophia Nguyen",
        category: "Blockchain / 10 weeks",
        newTag: true,
        price: "£6,500",
        oldPrice: "£2,000 (Early Bird)",
        comingSoon: true,
        buttonText: "Coming Soon",
        previewText: "Course Overview",
        icon: 'FaBitcoin',
        imgSrc: require('../assets/courseImages/Cover4.png'),
        description: "Explore the revolutionary world of blockchain technology and cryptocurrencies. Learn to develop decentralized applications (DApps) and smart contracts on Ethereum.",
        features: [
            { image: require('../assets/smallAssets/blockchain.png'), feature: 'Introduction to blockchain technology' },
            { image: require('../assets/smallAssets/whytech/money.png'), feature: 'Cryptocurrency fundamentals' },
            { image: require('../assets/smallAssets/contract.png'), feature: 'Smart contract development with Solidity' },
            { image: require('../assets/smallAssets/spanner.png'), feature: 'Building DApps on the Ethereum platform' },
            { image: require('../assets/smallAssets/padlock.png'), feature: 'Security best practices for blockchain applications' },
            { image: require('../assets/smallAssets/www.png'), feature: 'Blockchain\'s role in global finance and beyond' }
        ]
    },
    {
        title: "Data Science and Big Data Analytics Program",
        level: "Advanced",
        instructor: "Michael Lee",
        category: "Data Science / 14 weeks",
        newTag: true,
        price: "£8,500",
        oldPrice: "£2,200 (Early Bird)",
        comingSoon: true,
        buttonText: "Coming Soon",
        previewText: "Course Overview",
        icon: 'FaChartLine',
        imgSrc: require('../assets/courseImages/Cover4.png'),
        description: "Master the art of data analysis with Python, R, and machine learning techniques. Gain the skills needed to handle big data and turn it into actionable insights.",
        features: [
            { image: require('../assets/smallAssets/bar-chart.png'), feature: 'Data analysis with Python and R' },
            { image: require('../assets/smallAssets/algorithm.png'), feature: 'Machine learning algorithms and applications' },
            { image: require('../assets/smallAssets/hard-drive.png'), feature: 'Big data technologies like Hadoop and Spark' },
            { image: require('../assets/smallAssets/spanner.png'), feature: 'Data visualization with Tableau and Power BI' },
            { image: require('../assets/smallAssets/robot.png'), feature: 'Building predictive models' },
            { image: require('../assets/smallAssets/bulb.png'), feature: 'Capstone project: solving real-world data problems' }
        ]
    },
    {
        title: "DevOps Engineering and Cloud Infrastructure",
        level: "Intermediate",
        instructor: "Emma Clark",
        category: "DevOps / 8 weeks",
        newTag: true,
        price: "£9,900",
        oldPrice: "£1,800 (Early Bird)",
        comingSoon: true,
        buttonText: "Coming Soon",
        previewText: "Course Overview",
        icon: 'FaServer',
        imgSrc: require('../assets/courseImages/Cover4.png'),
        description: "Learn the essentials of DevOps practices and cloud infrastructure management. Get hands-on with CI/CD pipelines, containerization, and orchestration with Docker and Kubernetes.",
        features: [
            { image: require('../assets/smallAssets/spanner.png'), feature: 'Introduction to DevOps practices and tools' },
            { image: require('../assets/smallAssets/cloud-storage.png'), feature: 'CI/CD pipeline development' },
            { image: require('../assets/devops/docker.png'), feature: 'Containerization with Docker' },
            { image: require('../assets/smallAssets/cloud-storage.png'), feature: 'Orchestration with Kubernetes' },
            { image: require('../assets/devops/aws-icon.png'), feature: 'Cloud infrastructure management on AWS and Azure' },
            { image: require('../assets/smallAssets/bar-chart.png'), feature: 'Monitoring and scaling applications in the cloud' }
        ]
    }
];

const featureObject = [
    { image: require('../assets/smallAssets/percent.png'), feature: 'Interest-Free Payments' },
    { image: require('../assets/smallAssets/key.png'), feature: 'Access to all course content immediately' },
    { image: require('../assets/smallAssets/time.png'), feature: 'Lifetime access upon completion' },
    { image: require('../assets/smallAssets/mentor.png'), feature: 'Free trial and mentoring support included' }
];

export const pricingPlans = [
    {
        features: [
            { image: require('../assets/smallAssets/today.png'), feature: 'Monthly Installment Options' },
            ...featureObject,
        ]
    }
];

